import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMealsSuccess } from "../../../redux/mealsSlice";
import { RootState } from "../../../redux/store";
import { getMacrosFromMeals } from "../../../utils/misc-utils";
import { dailyMeals, ErrorMessage, mealList } from "../../../utils/types";
import { loadUserDayOfEating } from "../../../utils/db_connectors/database-connectors";
import NutritionDetailsCard from "./NutritionDetailsCard/NutritionDetailsCard";
import { RecipeCardLight } from "./RecipeCard/RecipeCardLight";
import { set } from "lodash";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";
import { IonRow, IonButton, IonAlert } from "@ionic/react";
import { addError } from "../../../redux/errorSlice";
import { generateMealPlan } from "../Services/mealplanServices";
import { loadMeals } from "../MealPlanUtils";

type MealPlanCustomViewInnerProps = {
    dateFrom: string,
    dateTo: string,
}

function MealPlanCustomViewInner(props: MealPlanCustomViewInnerProps) {

    // const loadedMeals = useSelector((state: RootState) => state.meals);
    // Filter meals directly in the useSelector hook
    const loadedMeals = useSelector((state: RootState) => {
        const allMeals = state.meals;
        return Object.keys(allMeals)
            .filter(date => date >= props.dateFrom && date <= props.dateTo)
            .reduce((acc, date) => {
                acc[date] = allMeals[date];
                return acc;
            }, {} as { [key: string]: any });
    });

    const user = useSelector((state: RootState) => state.auth.userUid);
    const dispatch = useDispatch();
    const [averageDailyMacros, setAverageDailyMacros] = useState({ calories: 0, protein: 0, carbs: 0, fat: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAlertGenerateMealPlanVisible, setIsAlertGenerateMealPlanVisible] = useState<boolean>(false);

    useEffect(()=>{
        setIsLoading(true);
        loadMeals(new Date(props.dateFrom), new Date(props.dateTo), loadedMeals, dispatch, user!);
        setIsLoading(false);
    },[user, props.dateFrom, props.dateTo, dispatch]);
    // useEffect(() => {
    //     console.log('triggered1')
    //     const fetchData = async () => {
    //         try {
    //         const dateFromDate = new Date(props.dateFrom);
    //         const dateToDate = new Date(props.dateTo);
    //         const newDailyMeals: dailyMeals = {};

    //         let currentDate = dateFromDate;
    //         let daysToLoad: string[] = [];
    //         // lets check available doe data, if doe not available in store, remember it to then load it from db
    //         while (currentDate <= dateToDate) {
    //             const currentDateString = currentDate.toISOString().split('T')[0];
    //             if (currentDateString in loadedMeals) {
    //                 newDailyMeals[currentDateString] = loadedMeals[currentDateString];
    //             } else {
    //                 daysToLoad.push(currentDateString)
    //             };
    //             currentDate.setDate(currentDate.getDate() + 1);
    //         }

    //         if(daysToLoad.length === 0) {
    //             setIsLoading(prev => false);
    //             return;
    //         }
    //             // load missing days from db 
    //         const res = await loadUserDayOfEating(user!, daysToLoad);

    //         //add newly loaded days to db, todo do this from redux store action
    //         Object.entries(res).forEach(([date, temp]) => {
    //             if (typeof temp === 'object' && temp !== null && 'recipes' in temp) {
    //                 const recipes = (temp as { recipes: any }).recipes;
    //                 dispatch(fetchMealsSuccess({ date: date, meals: recipes }));
    //             }
    //             else {
    //                 dispatch(fetchMealsSuccess({ date: date, meals: {} }));
    //             }
    //         });
    //         } catch (error) {

    //         } finally {
    //             setIsLoading(prev => false);
    //     };
    //     }
    //     if (user) {
    //         fetchData();
    //     }
    // }, [user, props.dateFrom, props.dateTo, dispatch]);


    // compute average daily macros for the period
    // todo check if it detectes newly generated meal plan or edited days ?
    useEffect(()=>{
        let currentDate = new Date(props.dateFrom);
        const dateToDate = new Date(props.dateTo);
        let sumMacros = { calories: 0, protein: 0, carbs: 0, fat: 0 };
        while(currentDate <= dateToDate) {
            const currentDateString = currentDate.toISOString().split('T')[0];
            if(currentDateString in loadedMeals) {
                const temp = getMacrosFromMeals(loadedMeals[currentDateString]);
                sumMacros.calories += temp.calories;
                sumMacros.protein += temp.protein;
                sumMacros.carbs += temp.carbs;
                sumMacros.fat += temp.fat;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        const nonEmptyDays = Object.keys(loadedMeals).filter((date) => Object.keys(loadedMeals[date]).length > 0).length;
        sumMacros.calories = Math.round(sumMacros.calories / nonEmptyDays);
        sumMacros.protein = Math.round(sumMacros.protein / nonEmptyDays);
        sumMacros.carbs = Math.round(sumMacros.carbs / nonEmptyDays);
        sumMacros.fat = Math.round(sumMacros.fat / nonEmptyDays);
       
        setAverageDailyMacros(sumMacros);

    }, [props.dateFrom, props.dateTo]);

    const [allDaysEmpty, setAllDaysEmpty] = useState<boolean>(false);
    useEffect(()=>{
        let currentDate = new Date(props.dateFrom);
        const dateToDate = new Date(props.dateTo);
        while(currentDate <= dateToDate) {
            const currentDateString = currentDate.toISOString().split('T')[0];
            if(loadedMeals[currentDateString] && Object.keys(loadedMeals[currentDateString]).length > 0) {
                setAllDaysEmpty(prev=>false);
                break;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
    },[props.dateFrom, props.dateTo]);


    function onClickGenerateMealPlan() {
        // var allDaysEmpty = false;
        if (!allDaysEmpty) {
            setIsAlertGenerateMealPlanVisible(true);
        }
        else {
            triggerGenerateMealPlan('replace');
            // generateMealPlan(loadedMeals, replaceOrComplete);
        }



        //     if(meals)
        // alrt, remplace ou complete ?

        // si remplace
        //genere tout
        //dispatch
        // si complete 
        // genere jours vides
        //dispatch 

        //check que maj du redux ne change pas les props de date

    }

    async function triggerGenerateMealPlan(replaceOrComplete: string) {
        setIsLoading(true);
        var dates = Object.keys(loadedMeals);
        if (replaceOrComplete === 'complete') {
            dates = dates.filter((date) => Object.keys(loadedMeals[date]).length === 0);
        }
        try {
            const res = await generateMealPlan(user!, dates, dispatch);
            if ('errors' in res && res.errors.length > 0) {
                for (const error of res.errors as ErrorMessage[]) {
                    dispatch(addError({ id: error.id, errorMessage: error.errorMessage }));
                }
                // return;
            }
            if (res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    dispatch(fetchMealsSuccess({ date: dates[i], meals: res.data[i] }));
                }
            }

            dispatch(addError({ id: new Date().getTime().toString(), errorMessage: "Successfully generated meal plan" }));
            setIsLoading(false);

        } catch (error) {
            console.error(error);
            // Handle error here
            setIsLoading(false); // Ensure isLoading is set to false even if there's an error.
        }
    }




    return (
     <>   {isLoading ? <LoadingGenie/> :
            <>
                <NutritionDetailsCard
                    sumMacros={averageDailyMacros}
                    isAverageCard={true}
                /> 

                <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IonButton onClick={() => onClickGenerateMealPlan()}>
                        Generate Meal plan
                    </IonButton>
                </IonRow>

                <IonAlert
                    header="Existing data detected"
                    message='Do you want to replace or complete the existing data?'
                    isOpen={isAlertGenerateMealPlanVisible}
                    onDidDismiss={() => setIsAlertGenerateMealPlanVisible(false)}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                            },
                        },
                        {
                            text: 'Continue',
                            role: 'confirm',
                        },
                    ]}
                    inputs={[
                        {
                            label: 'Replace',
                            type: 'radio',
                            value: 'replace',
                            //   checked: replaceOrComplete === 'replace',
                            handler: () => triggerGenerateMealPlan('replace')
                        },
                        {
                            label: 'Complete',
                            type: 'radio',
                            value: 'complete',
                            //   checked: replaceOrComplete === 'complete',
                            handler: () => triggerGenerateMealPlan('complete')

                        },
                    ]}
                // onDidDismiss={({ detail }) => console.log(`Dismissed with role: ${detail.role}`)}
                ></IonAlert>

                {(() => {
                    if(!allDaysEmpty) {
                        const dateFromDate = new Date(props.dateFrom);
                        const dateToDate = new Date(props.dateTo);
                        const recipeCards = [];

                        let currentDate = dateFromDate;

                        while (currentDate <= dateToDate) {
                            const currentDateString = currentDate.toISOString().split('T')[0];
                            const mealsForCurrentDate = loadedMeals[currentDateString];
                            recipeCards.push(
                                <RecipeCardLight key={currentDateString} date={currentDateString} meals={mealsForCurrentDate} />
                            );

                            currentDate.setDate(currentDate.getDate() + 1);
                        }
                        return recipeCards;
                    }
                    else{
                        return <IonRow class="ion-justify-content-center"><p>No data to show.</p></IonRow>;
                    }
                })()}
        </>
        }
    </>)
}

export { MealPlanCustomViewInner };