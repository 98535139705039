import { IonRow, IonDatetimeButton, IonModal, IonDatetime } from "@ionic/react";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { getLastMonday, getNextSunday } from "../MealPlanUtils";
import NutritionDetailsCard from "./NutritionDetailsCard/NutritionDetailsCard";
import { mealList } from "../../../utils/types";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { loadUserDayOfEating } from "../../../utils/db_connectors/database-connectors";
import { current } from "@reduxjs/toolkit";
import { fetchMealsSuccess } from "../../../redux/mealsSlice";
import { getMacrosFromMeals } from "../../../utils/misc-utils";
import { RecipeCardLight } from "./RecipeCard/RecipeCardLight";
import { MealPlanCustomViewInner } from "./MealPlanCustomViewInner";
import { setWeekViewDateFrom, setWeekViewDateTo } from "../../../redux/uxSlice";

type MealPlanCustomViewProps = {

}


function MealPlanCustomView() {
    const dateFrom = useSelector((state: RootState) => state.ux.weekViewDateFrom);
    const dateTo = useSelector((state: RootState) => state.ux.weekViewDateTo);
    const dispatch = useDispatch();
    // useState<string>(getLastMonday());
    // const [dateTo, setDateTo] = useState<string>(getNextSunday());


    return (
        <>
            <IonRow class="ion-justify-content-center" style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <p style={{marginTop:'auto',marginBottom:"auto"}}>From</p>
                <IonDatetimeButton datetime="dateFrom"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime
                        id="dateFrom"
                        presentation="date"
                        value={dateFrom}
                        onIonChange={(e) => dispatch(setWeekViewDateFrom(e.detail.value! as string))}
                        // e.detail.value! as string)}
                    ></IonDatetime>
                </IonModal>
                <p style={{marginTop:'auto',marginBottom:"auto"}}> to </p>
                <IonDatetimeButton datetime="dateTo"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime
                        id="dateTo"
                        presentation="date"
                        value={dateTo}
                        min={dateFrom}
                        onIonChange={(e) => {dispatch(setWeekViewDateTo(e.detail.value! as string))}}
                    ></IonDatetime>
                </IonModal>
            </IonRow>

           <MealPlanCustomViewInner dateFrom={dateFrom} dateTo={dateTo} />

        </>
    )

}

export { MealPlanCustomView };