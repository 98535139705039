import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Recipe } from "../../../utils/types";
import { IonPage, IonHeader, IonTitle, IonContent, IonToolbar, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonIcon, IonRow, IonSelect, IonSelectOption, IonBackButton } from "@ionic/react";
import { create, createOutline } from "ionicons/icons";
import { addRecipesToStore, editEditMode, editMealType, setViewingRecipeId, editUsersRecipe, lockIngredientInRecipe, saveRecipe, saveRecipeToCommonDB, setIsImport, updateServings } from "../../../redux/recipeSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { IngredientList } from "../../MealPlan/Components/IngredientList/IngredientList";
import { deleteIngredientFromRecipe } from "../../../redux/recipeSlice";
import { getMacrosFromIngredientList, getTodaysDay } from "../../../utils/misc-utils";
import { deleteUsersRecipeThunk } from "../../../redux/recipeSlice";
import { saveCleanedRecipe } from "../../MealPlan/Services/mealplanServices";
import { editRecipeName } from "../../../redux/recipeSlice";
import ObjectID from "bson-objectid";
import { addError } from "../../../redux/errorSlice";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";
import { set } from "lodash";

type SingleRecipeViewProps = {
    // usersRecipe: boolean;
}

function SingleRecipeView(props: SingleRecipeViewProps) {
    const location = useLocation();
    const useAppDispatch = () => useDispatch<AppDispatch>();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const user = useSelector((state: RootState) => state.auth.userUid);
    const isUserAdmin = useSelector((state: RootState) => state.auth.isAdmin);

    //to delete maybe
    const tempRecipeViewingMode = useSelector((state: RootState) => state.ux.segment);
    const editMode = useSelector((state: RootState) => state.recipe.editMode);

    const originalRecipeId = useSelector((state: RootState) => state.recipe.viewingRecipeId);//, (prev,next)=>prev!==next);

    const originalRecipe = useSelector((state: RootState) => {
        const recipes = state.recipe.recipes;
        return recipes && originalRecipeId ? recipes[originalRecipeId]?.recipe : null;
    },);
    const editingRecipe = useSelector((state: RootState) => {
        const recipes = state.recipe.recipes;
        return recipes ? recipes['-1']?.recipe : null;
    });

    const [viewingRecipe, setViewingRecipe] = useState<Recipe | null>(originalRecipe);
    // setViewingRecipe(editMode ? editingRecipe : originalRecipe);
    // Update the recipe data here

    const isImport = useSelector((state: RootState) => state.recipe.isImport);
    const isUsersRecipe = useSelector((state: RootState) => state.recipe.isUsersRecipe);

    const [loading, setLoading] = useState(true);

    const [macros, setMacros] = useState({ caloriesPerServing: 0, proteinPerServing: 0, carbsPerServing: 0, fatPerServing: 0 });

    // const [editRecipe, setEditRecipe] = useState<Recipe | null>(null);
    // const [isImport, setIsImport] = useState(false);
    // const [editMode, setEditMode] = useState(false);
    // const [editUsersRecipe, setEditUsersRecipe] = useState(false);




    useEffect(() => {
        if (location.state && Object.keys(location.state as object).length > 0) {

            var { key, recipe, usersRecipe, editMode, isImport } = location.state as { key: string, recipe: Recipe, usersRecipe: boolean, editMode: boolean, isImport: boolean };
            //should only intiialize when recipe is undefined
            if (recipe !== undefined) {
                dispatch(setViewingRecipeId(recipe.recipeId));
                // add the recipe at -1 key to the store to keep track of the recipe between pages
                dispatch(addRecipesToStore([[{ ...recipe, recipeId: '-1' }], usersRecipe]));

                dispatch(setIsImport(isImport));
                dispatch(editEditMode(editMode));
                dispatch(editUsersRecipe(usersRecipe));

                history.replace({ state: {} });
            }
        }

        setLoading(false);

    }, [location.state, dispatch]);

    useEffect(() => {
        if (editMode) {
            if (editingRecipe === null) dispatch(addRecipesToStore([[{ ...viewingRecipe!, recipeId: '-1' }], isUsersRecipe]));
            setViewingRecipe(editingRecipe);
        } else {
            setViewingRecipe(originalRecipe);
        }
    }, [originalRecipe, editMode, viewingRecipe, editingRecipe, isUsersRecipe, dispatch]);



    // useEffect(()=>{
    //     console.log("ingredients state",recipeFromState?.ingredients)
    // },[recipeFromState?.ingredients])

    // useEffect(() => {
    //     if (recipeFromState !== null) {
    //         dispatch(editRecipe(recipeFromState)); // Initialize recipe in the store
    //         dispatch(editEditMode(editModeFromState));
    //         dispatch(editUsersRecipe(usersRecipeFromState));
    //     }
    // }, [recipeFromState, editModeFromState, usersRecipeFromState]);

    useEffect(() => {
        // Your code here...
        if (viewingRecipe) {
            var macros = getMacrosFromIngredientList(viewingRecipe.ingredients)

            const updatedMacros = {
                caloriesPerServing: macros.calories / viewingRecipe.servings,
                proteinPerServing: macros.protein / viewingRecipe.servings,
                carbsPerServing: macros.carbs / viewingRecipe.servings,
                fatPerServing: macros.fat / viewingRecipe.servings
            };
            setMacros(updatedMacros);
        }
    }, [viewingRecipe, viewingRecipe?.ingredients, viewingRecipe?.servings]);


    function onClickAddIngredient() {
        //redirect to add ingredient page wth necessary state parameters
        history.push({
            pathname: ('/mealplan/add-ingredient'),
            state: { recipeID: originalRecipeId }
        });

    }

    function onClickDeleteIngredient(key: string) {
        // Convert the key from a string to a number
        const ingredientKey = Number(key);
        // Dispatch the deleteIngredient action
        dispatch(deleteIngredientFromRecipe(ingredientKey));
    }

    function onClickLockIngredient(key: number) {
        dispatch(lockIngredientInRecipe(key));
    }

    const handleMealChange = (event: CustomEvent) => {
        var tempNewMealType = event.detail.value as string[];
        dispatch(editMealType({ newMealType: tempNewMealType }))
        // setSelectedMeals(event.detail.value as string[]); // Cast to string[] assuming the values are strings
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        {/* <IonButton onClick={() => {
                            history.push('/recipes');
                            // setTimeout(() => dispatch(clearRecipe()), 0);
                        }}>
                            Back
                        </IonButton> */}
                        <IonBackButton defaultHref="#" />

                    </IonButtons>
                    <IonTitle>Recipe</IonTitle>
                    {isUsersRecipe &&
                        <IonButtons slot="end">
                            <IonButton onClick={() => { dispatch(editEditMode(!editMode)) }} disabled={isImport}>
                                <IonIcon icon={editMode ? create : createOutline} />
                            </IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading ? <LoadingGenie /> : <>
                <IonRow>
                        <img alt="Silhouette of mountains" src={viewingRecipe?.imageURL ? viewingRecipe.imageURL : 'https://ionicframework.com/docs/img/demos/thumbnail.svg'} />

                </IonRow>
                <IonItem>
                    <IonLabel>Name</IonLabel>
                        <IonInput
                            value={viewingRecipe?.recipeName}
                            readonly={!editMode}
                            disabled={!isUsersRecipe && !editMode}
                            slot="end"
                            class="ion-text-end"
                            onIonInput={e => {
                                dispatch(editRecipeName(e.detail.value))
                            }
                            }
                        ></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Serves</IonLabel>
                        <IonInput
                            value={viewingRecipe?.servings}
                            readonly={!editMode}
                            disabled={!isUsersRecipe}
                            slot="end"
                            class="ion-text-end"
                            onIonInput={e => dispatch(updateServings(Number(e.detail.value)))}

                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Calories per serving</IonLabel>
                        <IonInput value={macros.caloriesPerServing} disabled={true}
                        slot="end" class="ion-text-end"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Protein per serving</IonLabel>
                        <IonInput value={macros.proteinPerServing} disabled={true}
                        slot="end" class="ion-text-end"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Carbs per serving</IonLabel>
                        <IonInput value={macros.carbsPerServing} disabled={true}
                        slot="end" class="ion-text-end"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Fat per serving</IonLabel>
                        <IonInput value={macros.fatPerServing} disabled={true}
                        slot="end" class="ion-text-end"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>MealType</IonLabel>
                    <IonSelect
                        aria-label="Meal"
                        placeholder="Select meal type"
                        multiple={true}
                            value={viewingRecipe?.mealType}
                        onIonChange={handleMealChange}
                        disabled={!editMode}
                        justify="end"
                    >
                        <IonSelectOption value="breakfast">Breakfast</IonSelectOption>
                        <IonSelectOption value="meal">Meal</IonSelectOption>
                        <IonSelectOption value="snack">Snack</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <h3>Ingredients</h3>
                    {viewingRecipe && <IngredientList
                        ingredients={viewingRecipe?.ingredients}
                        editable={(editMode && isUsersRecipe) || isUserAdmin}
                        onClickLockIngredient={onClickLockIngredient}
                        onClickDeleteIngredient={onClickDeleteIngredient}
                        onClickAddIngredient={onClickAddIngredient}
                        importing={isImport}
                    />
                }

                    <IonButton
                        expand="block"
                        onClick={() => {
                            history.push({
                                pathname: "/mealplan/editRecipe/",
                                state: { date: getTodaysDay(), recipe: viewingRecipe }
                            });
                        }}
                        disabled={editMode}
                    >
                        Plan
                    </IonButton>

                    {tempRecipeViewingMode !== 'clean' && editMode && isUserAdmin &&
                    <>
                        <IonButton expand="block" onClick={() => {
                            //beware of id ? still -1 ? does original id exist ?
                            dispatch(saveRecipeToCommonDB({ recipe: viewingRecipe! }));
                        }}>
                            Save to common DB
                        </IonButton>

                    </>
                }

                    {tempRecipeViewingMode !== 'clean' && editMode && isUsersRecipe &&
                    <>
                        <IonButton expand="block" onClick={() => {
                            if (viewingRecipe !== null) {
                                let tempRecipe: Recipe = { ...viewingRecipe, ...macros };

                                if (isImport) {
                                    tempRecipe = { ...tempRecipe, recipeId: new ObjectID().toString() };
                                }
                                else if (originalRecipe !== null) {
                                    tempRecipe = { ...tempRecipe, recipeId: originalRecipe.recipeId };
                                }
                                dispatch(saveRecipe({ user_id: user!, recipe: tempRecipe }));

                            }
                        }}>
                            Save
                        </IonButton>
                        <IonButton color="danger" expand="block" onClick={() => {
                            setLoading(prev => true);
                            if (isUsersRecipe && viewingRecipe !== null && originalRecipeId !== null) {
                                dispatch(deleteUsersRecipeThunk({ user_id: user!, recipeId: originalRecipeId })).then(() => {
                                    dispatch(addError({ id: new Date().getTime().toString(), errorMessage: "Recipe deleted" }));
                                    history.push('/recipes');
                                    // setLoading(prev=>false);

                                });
                            }
                            // dispatch(addError({ id: new Date().getTime().toString(), errorMessage: "Recipe deleted" }));
                            // history.push('/recipes');
                        }}>
                            Delete
                        </IonButton>
                    </>}
                {tempRecipeViewingMode === 'clean' && editMode &&
                    <>
                        <IonButton expand="block" onClick={() => saveCleanedRecipe(viewingRecipe!, "cleaned_from_url", "cleaned")}>
                            Save and Mark cleaned
                        </IonButton>
                        <IonButton expand="block" color='danger' onClick={() => saveCleanedRecipe(viewingRecipe!, "cleaned_from_url", "discarded")}>
                            Mark discarded
                        </IonButton>
                    </>
                }
                </>}
            </IonContent>
        </IonPage>
    );
}

export { SingleRecipeView }