import { IonButton, IonImg, IonInput, IonPage, IonText, IonContent, IonRow, IonDatetime, IonSelect, IonSelectOption, IonRadio, IonRadioGroup, IonLabel } from "@ionic/react";
import { goal2deficit, mealType, OnboardingUserFormData } from "../../../../utils/types";

import logo from '../../../../assets/noun-genie-1637607-outlined.svg'; // replace with the path to your logo
import { useState, useRef, useEffect } from "react";
import './onBoarding2.css'; // Correct import statement
import { useMaskito, } from '@maskito/react';
import { DoeStructurePage } from "./DoeStructurePage";
import { RecipeSwipePage } from "../RecipeSwipePage/recipeSwipePage";
import { DoeStructureComponent } from "./DoeStructureComponent";
import { computeMacros, computeMaintenance } from "../../../../utils/misc-utils";
import SignupPageComponent from "./SignUpPage";
import { CurrencyExchange } from "@mui/icons-material";

type OnBoardingSlidesProps = {
    onboardingFormData: OnboardingUserFormData;
    setOnboardingFormData: React.Dispatch<React.SetStateAction<OnboardingUserFormData>>;
    setUserIsOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
    setOnboardingRecipeLikes: React.Dispatch<React.SetStateAction<any[]>>;
    retaking: boolean;
}

function OnBoarding2(props: OnBoardingSlidesProps) {
    const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
    const inputRef = useRef<HTMLIonInputElement>(null);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const handleInputChange = (key: keyof OnboardingUserFormData, value: string | number | {}) => {
        props.setOnboardingFormData(prevData => ({
            ...prevData,
            [key]: typeof value === 'number' ? Math.round(value) : value
        } as OnboardingUserFormData));
    };

    const triggerMacroCalculation = () => {
        const requiredKeys: (keyof OnboardingUserFormData)[] = ['birthdate', 'gender', 'height', 'weight', 'activityLevel', 'goal'];
        const isFormEmpty = requiredKeys.some(key => !props.onboardingFormData[key]);
        var tempMaintenance = computeMaintenance(props.onboardingFormData);
        handleInputChange('caloricMaintenance', tempMaintenance);
        var caloricGoal = tempMaintenance + goal2deficit[props.onboardingFormData.goal as keyof typeof goal2deficit];
        handleInputChange('caloricTarget', caloricGoal);
        var tempMacrosTarget = computeMacros(caloricGoal);
        handleInputChange('macrosTarget', tempMacrosTarget);
    };

    const goToNextQuestion = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        if (currentActiveIndex === 7) {
            triggerMacroCalculation();
        }
        const currentSection = document.querySelector(`.onBoarding-${currentActiveIndex}`);
        const nextSection = document.querySelector(`.onBoarding-${currentActiveIndex + 1}`);
        if (currentSection && nextSection) {
            currentSection.classList.remove('active');
            currentSection.classList.add('exiting');
            nextSection.classList.remove('returning');
            nextSection.classList.add('active');
        }
        // Blur the input field to hide the keyboard
        if (inputRef.current) {
            inputRef.current.blur();
        }

        setTimeout(() => {
            setCurrentActiveIndex((prevIndex) => (prevIndex + 1));
            setIsTransitioning(false);
        }, 500); // Match the duration of the CSS transition
    };

    const goToPrevQuestion = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        const currentSection = document.querySelector(`.onBoarding-${currentActiveIndex}`);
        const prevSection = document.querySelector(`.onBoarding-${currentActiveIndex - 1}`);
        if (currentSection && prevSection) {
            currentSection.classList.remove('active');
            currentSection.classList.add('returning');
            prevSection.classList.remove('exiting');
            prevSection.classList.add('active');
        }

        setTimeout(() => {
            setCurrentActiveIndex((prevIndex) => (prevIndex - 1));
            setIsTransitioning(false);

        }, 500); // Match the duration of the CSS transition
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.getInputElement().then(input => input.focus());
        }
    }, [currentActiveIndex]);

    const buttonLine = (key:string|null) => {
        const isNextButtonVisible = (currentActiveIndex < 11)
            || currentActiveIndex === 8
            || (currentActiveIndex === 9 && props.onboardingFormData.meals.length !== 0);
            var tempIsNextable;
            if(key !== null){
                const value = props.onboardingFormData[key as keyof OnboardingUserFormData];
                tempIsNextable = (typeof value === 'string' || Array.isArray(value)) && value.length > 0 || typeof value === 'number' && value > 0
            }
            else{
                tempIsNextable = true;
            }
        return (
            <IonRow className="button-line">
                {(currentActiveIndex > 0) && <IonButton onClick={goToPrevQuestion} className="prev-button">Prev</IonButton>}
                {tempIsNextable && currentActiveIndex < 11 && <IonButton onClick={goToNextQuestion} className="next-button">Next</IonButton>}
            </IonRow>

        );
    };

    const dateMaskOptions = {
        mask: [
            /\d/, /\d/, /\d/, /\d/, '-',
            /\d/, /\d/, '-',
            /\d/, /\d/
        ],
    };
    const dateMask = useMaskito({ options: dateMaskOptions });


    return (
        <IonPage>
            <IonContent>
                <IonRow>
                    <div className="onBoarding-section onBoarding-0 active">
                        <div className="content ion-text-center">
                            <h1 className="title">Nutrition Genie</h1>
                            <p>Tailored meal planning to reach your fitness goal</p>
                            <div className="logo-container">
                                <IonImg src={logo} alt="Logo" className="logo" />
                            </div>
                        </div>
                        <div className="footer ion-text-center">
                            <IonButton
                                expand="full"
                                color="dark"
                                className="start-button"
                                shape="round"
                                onClick={goToNextQuestion}
                                style={{marginBottom:'10px'}}
                            >
                                Start
                            </IonButton>
                            <IonText color="">
                                Already have an account? <a href="/login">Login</a>
                            </IonText>
                        </div>
                    </div>
                </IonRow>

                <IonRow>
                    <div className="onBoarding-section onBoarding-1">
                        <div className="question-container">
                            <div className={`question ${currentActiveIndex === 1 ? 'active' : ''}`}>
                                {/* <label>What's your name?</label> */}
                                <h3>Hi !
                                    <br />What's your name ? </h3>
                                <IonInput
                                    // label-placement="floating"
                                    placeholder="Genie"
                                    // label="Tell us your name !"
                                    ref={currentActiveIndex === 1 ? inputRef : null}
                                    type="text"
                                    value={props.onboardingFormData?.username}
                                    onIonInput={(e) => {
                                        props.setOnboardingFormData({
                                        ...props.onboardingFormData,
                                        username: e.detail.value as string
                                        });
                                    }
                                    }
                                />
                            </div>
                        </div>
                        <div className='footer'>

                            {buttonLine('username')}

                        </div>

                    </div>
                </IonRow>

                <IonRow>
                    <div className="onBoarding-section onBoarding-2">

                        <h3>When were you born {props.onboardingFormData.username}?</h3>
                        <div className="question-container">
                            <div className={`question ${currentActiveIndex === 2 ? 'active' : ''}`}>
                                {/* <label>What's your name?</label> */}
                                <IonInput
                                    ref={async (dateInput) => {
                                        if (dateInput) {
                                            const input = await dateInput.getInputElement();
                                            dateMask(input);
                                            if (currentActiveIndex === 2) {
                                                input.focus();
                                            }
                                        }
                                    }}
                                    autoFocus={currentActiveIndex === 2}
                                    // label="What's your birth day (YYYY-MM-DD)?"
                                    // label-placement="floating"
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="YYYY-MM-DD"
                                    // ref={currentActiveIndex === 2 ? inputRef : null}

                                    value={props.onboardingFormData?.birthdate}
                                    onIonInput={(e) => {
                                        props.setOnboardingFormData({
                                        ...props.onboardingFormData,
                                        birthdate: e.detail.value as string
                                        });
                                    }}
                                />

                            </div>
                        </div>
                        <div className='footer'>
                            {buttonLine('birthdate')}
                        </div>
                    </div>
                </IonRow>

                <IonRow>
                    <div className="onBoarding-section onBoarding-3">
                        <h3>What's your assigned gender at birth ?</h3>

                        <div className="question-container">
                            <div
                                className={`question ${currentActiveIndex === 3 ? 'active' : ''}`}
                                style={{ textAlign: "left" }}>
                                {/* <label>What's your name?</label> */}
                                <IonRadioGroup
                                    // value="end"
                                    className="ion-radio-custom"
                                    value={props.onboardingFormData?.gender}
                                    onIonChange={(e) => {
                                        props.setOnboardingFormData({
                                        ...props.onboardingFormData,
                                        gender: e.detail.value
                                        });
                                    }}>
                                    <IonRadio value="female" labelPlacement="end">
                                        Female
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="male" labelPlacement="end">
                                        Male
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="intersex" labelPlacement="end">
                                        Intersex
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="unknown" labelPlacement="end">
                                        Prefer not to say
                                    </IonRadio>
                                    <br />
                                </IonRadioGroup>
                            </div>
                        </div>
                        <div className='footer'>
                            {buttonLine('gender')}
                        </div>
                    </div>
                </IonRow>

                <IonRow>
                    <div className="onBoarding-section onBoarding-4">
                        <h3>How tall are you (in cm) ?</h3>
                        <div className="question-container">
                            <div
                                className={`question ${currentActiveIndex === 4 ? 'active' : ''}`}>
                                {/* <label>What's your name?</label> */}
                                <IonInput
                                    // label-placement="floating"
                                    // label="How tall are you (cm)?"
                                    ref={currentActiveIndex === 4 ? inputRef : null}
                                    type="number"
                                    inputmode="numeric"
                                    placeholder="180"
                                    value={props.onboardingFormData?.height ==0 ? '' : props.onboardingFormData?.height}
                                    onIonInput={(e) => {
                                        props.setOnboardingFormData({
                                        ...props.onboardingFormData,
                                        height: e.detail.value ? parseInt(e.detail.value) : 0
                                        });
                                    }
                                    }
                                />
                            </div>
                        </div>
                        <div className='footer'>

                            {buttonLine('height')}

                        </div>

                    </div>
                </IonRow>

                <IonRow>
                    <div className="onBoarding-section onBoarding-5">
                        <h3>How much do you weigh (in kg) ?</h3>
                        <div className="question-container">
                            <div className={`question ${currentActiveIndex === 5 ? 'active' : ''}`}>
                                {/* <label>What's your name?</label> */}
                                <IonInput
                                    // label-placement="floating"
                                    // label="How much do you weigh (kg)?"
                                    ref={currentActiveIndex === 5 ? inputRef : null}
                                    type="number"
                                    inputmode="numeric"
                                    placeholder="80"
                                    value={props.onboardingFormData?.weight == 0 ? '' : props.onboardingFormData?.weight}
                                    onIonInput={(e) => {
                                        props.setOnboardingFormData({
                                        ...props.onboardingFormData,
                                        weight: e.detail.value ? parseInt(e.detail.value) : 0
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className='footer'>

                            {buttonLine('weight')}

                        </div>

                    </div>
                </IonRow>

                <IonRow>
                    <div className="onBoarding-section onBoarding-6" style={{ overflow: 'auto' }}>
                        <h3>How would you describe your activity level ?</h3>

                        <div className="question-container" >
                            <div
                                className={`question ${currentActiveIndex === 6 ? 'active' : ''}`}
                                style={{
                                    textAlign: "left",
                                    maxWidth: '100vw',
                                    padding: '16px',
                                    boxSizing: 'border-box'
                                }}>

                                <IonRadioGroup value={props.onboardingFormData?.activityLevel}
                                    onIonChange={(e) => {
                                        props.setOnboardingFormData({
                                        ...props.onboardingFormData,
                                        activityLevel: e.detail.value
                                        });
                                    }}
                                >
                                    <IonRadio value="sedentary" labelPlacement="end">
                                        Sedentary <br />(little to no exercise)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="lightly active" labelPlacement="end">
                                        Lightly active <br />(light exercise/sports 1-3 days a week)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="moderately active" labelPlacement="end">
                                        Moderately active <br />(moderate exercise/sports 3-5 days a week)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="very active" labelPlacement="end">
                                        Very active <br />(hard exercise/sports 6-7 days a week)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="extra active" labelPlacement="end">
                                        Extra active <br />(very hard exercise/sports & physical job <br />or training twice a day)
                                    </IonRadio>
                                    <br />
                                </IonRadioGroup>
                            </div>
                        </div>
                        <div className='footer'>
                            {buttonLine('activityLevel')}
                        </div>
                    </div>
                </IonRow>

                <IonRow>
                    <div className="onBoarding-section onBoarding-7" style={{ overflow: 'auto', padding: '16px', boxSizing: 'border-box' }}>
                        <div className="question-container" style={{ width: '100%', padding: '0 16px', boxSizing: 'border-box', overflow: 'auto' }}>
                            <h3>What's your current weight goal? </h3>
                            <div className={`question ${currentActiveIndex === 7 ? 'active' : ''}`} style={{ textAlign: "left", width: '100%', padding: '16px', boxSizing: 'border-box' }}>
                                <IonRadioGroup
                                    value={props.onboardingFormData?.goal}
                                    onIonChange={(e) => {
                                        props.setOnboardingFormData({ ...props.onboardingFormData, goal: e.detail.value });                                    
                                    }}
                                >
                                    <IonRadio value="extraLoss" labelPlacement="end">
                                        Extreme weightloss (-1kg/week)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="loss" labelPlacement="end">
                                        Weightloss (-500g/week)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="mildLoss" labelPlacement="end">
                                        Mild weightloss (-250g/week)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="maintain" labelPlacement="end">
                                        Maintenance
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="mildgain" labelPlacement="end">
                                        Mild weight gain (+250g/week)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="gain" labelPlacement="end">
                                        Weight gain (+500g/week)
                                    </IonRadio>
                                    <br />
                                    <IonRadio value="extremeGain" labelPlacement="end">
                                        Extreme weight gain (+1kg/week)
                                    </IonRadio>
                                    <br />
                                </IonRadioGroup>
                            </div>
                        </div>
                        <div className='footer'>
                            {buttonLine('goal')}
                        </div>
                    </div>
                </IonRow>

                <IonRow className='onBoarding-section onBoarding-8'>
                    <p>Here are your macros</p>
                    <p>Maintenance: {props.onboardingFormData.caloricMaintenance}</p>
                    <p>Calories: {props.onboardingFormData.caloricTarget}</p>
                    {/* <p>Macros: {props.userData.macrosTarget}</p> */}
                    <div className='footer'>
                        {buttonLine(null)}
                    </div>
                </IonRow>
                <IonRow className='onBoarding-section onBoarding-9'>
                    <div style={{ width: '100%' }}>

                        <DoeStructureComponent
                            mealList={props.onboardingFormData.meals}
                            setMealList={(mealList: mealType[]) => props.setOnboardingFormData(prevState => ({
                                ...prevState,
                                meals: mealList
                            }))}
                        />
                    </div>
                    <div className='footer'>
                        {buttonLine('meals')}
                    </div>
                </IonRow>

                <IonRow className='onBoarding-section onBoarding-10'>
                    {/* <div style={{height:'calc(100vh-300px)', margin:0, padding:0}}> */}
                        <RecipeSwipePage
                            // goToNextSlide={goToNextSlide}
                            // goToPrevSlide={goToPrevSlide}
                            setOnboardingRecipeLikes={props.setOnboardingRecipeLikes}
                        />
                        {/* </div> */}

                    <div className='footer'>
                        {buttonLine(null)}
                    </div>
                    {/* </div> */}
                </IonRow>

                {/* <IonRow className='onBoarding-section onBoarding-11'>
                    <h3>Checkout your tailored meal plan !</h3>
                    <div className='footer'>
                        {buttonLine()}
                    </div>
                </IonRow> */}

                <IonRow className='onBoarding-section onBoarding-11'>
                    <SignupPageComponent isUserOnboarding={true} setIsUserOnboarding={props.setUserIsOnboarding} />
                    <div className='footer'>
                        {buttonLine(null)}
                    </div>
                </IonRow>



                {/* Add more sections as needed */}
            </IonContent>
        </IonPage>
    )
}

export { OnBoarding2 };