import { useContext, useEffect, useState } from "react";
import { Recipe } from "../../../utils/types";
import { RecipeCardWrapper } from "../../Onboarding/Components/RecipeSwipePage/RecipeCardWrapper";
import { UserContext } from "../../../App";
import { getRecipesToBrowse } from "../services/RecipePageServices";
import { updateRecipeLikes } from "../../../utils/db_connectors/database-connectors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";
import { editRecipeLike, setLoadedDbRecipes } from "../../../redux/recipeSlice";

type SwipeRecipeViewProps = {
}

function SwipeRecipeView(props: SwipeRecipeViewProps) {
    const [recipeStack, setRecipeStack] = useState<Recipe[]>([]);
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    // const loadedDbRecipes = useSelector((state: RootState) => state.recipe.loadedDbRecipes);
    const dispatch: AppDispatch = useDispatch();
    // const dbRecipes = useSelector((state: RootState) => state.recipe.recipes);

    useEffect(() => {
        if (user) {
            // setRecipeStack(prev => []);
            initializeStackDbRecipes();
        }
    }, [user]);

    useEffect(() => {
        console.log('recipe stack', recipeStack)
    }, [recipeStack]);

    //TODO mutualize with browse recipe cards view
    async function initializeStackDbRecipes() {
            setIsLoading(true);
            var temp: Recipe[] = [];
            // if (props.segment == "browse") {
            temp = await getRecipesToBrowse(0, user!, '')
            dispatch({ type: 'recipe/addRecipesToStore', payload: [temp, false] });
            dispatch(setLoadedDbRecipes(true));

            // filter temp on likeStatus null
            temp = temp.filter(recipe => recipe.like === undefined || recipe.like === null);
            setRecipeStack(temp);          
            setIsLoading(false);


            setIsLoading(false);


    }


    function onSwipeLeft(recipeId: string) {
        dispatch(editRecipeLike({ user_id: user!, recipeId, likeStatus: false }));
        // updateRecipeLikes(user!, [{ recipe_id: recipeId, likeStatus: false }])
    }


    function onSwipeRight(recipeId: string) {
        dispatch(editRecipeLike({ user_id: user!, recipeId, likeStatus: true }));
        // updateRecipeLikes(user!, [{ recipe_id: recipeId, likeStatus: true }])
    }

    
    return (
        <>
        {isLoading && <LoadingGenie/>}
        {!isLoading && recipeStack.length === 0 ? <p>No Recipes left to swipe</p>
        :
            <RecipeCardWrapper recipeStack={recipeStack} onSwipeRight={onSwipeRight} onSwipeLeft={onSwipeLeft} />
        }
        </>
    )
}

export { SwipeRecipeView };