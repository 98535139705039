import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonIcon, IonChip, IonRow } from "@ionic/react";
import { useRef, useEffect, useState, useContext, useMemo } from "react";
import { getLast7Days, getLast7DaysFullString, getWeightData } from "../../Services/dashboardServices";
import { weightData } from "../../../../utils/types";
import { UserContext } from "../../../../App";
import { getTodaysDay, dateToString, getDateRelative } from "../../../../utils/misc-utils";
import { get } from "lodash";
import { addOutline } from "ionicons/icons";
import { getWeightEntries } from "../../../../redux/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";

type weightTrackingCardComponentProps = {
    // weightData: Record<string, number>;
}


function WeightTrackingCardComponent(props: weightTrackingCardComponentProps) {
    // const date14DaysAgo = useMemo(() => dateToString(getDateRelative(-14)), []);
    // const today = useMemo(() => dateToString(new Date()), []);
    // const [averageWeight7days, setAverageWeight7days] = useState<number>(0);
    // const weightDataLoadingStatus = useSelector((state: RootState) => state.dashboard.weightDataLoadingStatus);


    // const dispatch = useDispatch<AppDispatch>();

    // const weightData:Record<string, number> = useSelector((state: RootState) => state.dashboard.weightEntries);
    const rollingAverageData = useSelector((state: RootState) => state.dashboard.rollingAverageWeightData);
    // useEffect(() => {
    //     if (weightDataLoadingStatus === 'idle') {
    //         dispatch(getWeightEntries({ dateFrom: date14DaysAgo }));
    //     }
    // }, [dispatch, date14DaysAgo, weightDataLoadingStatus]);

    // useEffect(() => {
    //     if (weightDataLoadingStatus === 'succeeded') {
    //         const weightOfLast14Days = Object.entries(weightData)
    //             .filter(([date, weight]) => date >= date14DaysAgo && date <= today)
    //             .map(([date, weight]) => weight);

    //         const averageWeight = weightOfLast7Days.reduce((sum, weight) => sum + Number(weight), 0) / weightOfLast7Days.length;
    //         setAverageWeight7days(Number(averageWeight.toFixed(1)));
    //     }
    // }, [weightDataLoadingStatus, weightData, date7DaysAgo, today]);

    // useEffect(()=>{
    //     console.log('weight data', weightData);
    // },[weightData]);

    
    // const filteredWeightOfLast14Days = useMemo(() => {
    //     return Object.keys(weightOfLast14Days)
    //         .filter(date => date >= date14DaysAgo && date <= today)
    //         .reduce((acc: Record<string, number>, date) => {
    //             acc[date] = weightOfLast14Days[date];
    //             return acc;
    //         }, {});
    // }, [weightOfLast14Days, date14DaysAgo, today]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         await dispatch(getWeightEntries({ dateFrom: date14DaysAgo }));
    //     };
    //     fetchData();
    // }, [dispatch, date14DaysAgo]);

    // useEffect(() => {
    //     console.log('weight data', filteredWeightOfLast14Days);
    //     //average weight of last 14 days
    //     let sum = 0;
    //     let count = 0;
    //     for (const key in filteredWeightOfLast14Days) {
    //         sum += filteredWeightOfLast14Days[key];
    //         count++;
    //     }
    //     const average = sum / count;
    //     setAverageWeight14days(average);
    //     console.log('average weight of last 14 days', average);
    // }, [filteredWeightOfLast14Days]);


    // const last7daysFullStrings = getLast7DaysFullString();
    // const last7daysDisplay = getLast7Days();

    // const dailyWeightDataArray = last7daysFullStrings.map(date => {
    //     const weightForDate = props.weightData[date];
    //     return weightForDate || null;
    // });


   

    // useEffect(()=>{
    //     console.log('weight data', weightOfLast14Days);
    // },[weightOfLast14Days]);

    return (
        <IonCard >
            {/* <IonCardHeader>
                <IonCardTitle>Weight tracking</IonCardTitle>
            </IonCardHeader> */}
            <IonCardContent>
                {/* {weightDataLoadingStatus === 'loading' ? <h3>Loading...</h3> : */}
                <>
         
                <h1>{rollingAverageData[(new Date()).toISOString().split('T')[0]]} kg</h1> average weight over the last 7 days
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IonButton fill="clear" shape="round" routerLink="/dashboard/weightInput">
                        LOG WEIGHT<IonIcon slot="end" icon={addOutline}></IonIcon>
                    </IonButton>                  
                </div>
                </>
            </IonCardContent>
        </IonCard>
    )
}

export { WeightTrackingCardComponent };