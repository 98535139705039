import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPasswordBothLevels, signInWithGoogle } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Input, Row, Col, Button, Form, Alert } from 'antd';
import { IonPage, IonButtons, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonInput, IonLabel, IonItem, IonButton, IonAlert, IonIcon } from '@ionic/react';
import { EmailInput } from "../components/EmailInput/EmailInput";
import { eyeOff, eye } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { version } from "../utils/api_connectors/backend";


function LoginPageComponent() {

    // const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const [errorList, setErrorList] = useState<Array<String>>([]); // used to display errors catched to user
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const dispatch = useDispatch();

    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (loading) {
    //         // maybe trigger a loading screen
    //         return;
    //     }
    //     if (user) {
    //         // navigate("/");
    //     }
    // }, [user, loading]);

    async function onFinish() {
        var user = await signInWithEmailAndPasswordBothLevels(email, password, dispatch);
        // if (!error) {
        //     console.log('Success:', values);
        // }
        // else {
        //     console.log('ERROR LOGGING IN');
        //     setErrorList([...errorList, error.message]);
        //     //show error notification
        // }
    };


    const onCloseError = (e: String) => {
        setErrorList([...errorList].splice(errorList.indexOf(e), 1));
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <IonPage>
            <IonToolbar >
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/"></IonBackButton>
                </IonButtons>
                <IonTitle>Login</IonTitle>
            </IonToolbar>

            <IonContent>
                {errorList.length > 0 && errorList.map(error => (
                    <IonAlert
                        isOpen={true}
                        onDidDismiss={() => onCloseError(error)}
                        header={'Error'}
                        message={error as string}
                        buttons={['OK']}
                    />
                ))}
                <IonItem>
                    <EmailInput email={email} setEmail={setEmail} isValid={isValid} setIsValid={setIsValid} />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput
                        type={showPassword ? 'text' : 'password'}
                        value={password}

                        onIonInput={e => setPassword(e.detail.value!)} />
                    <IonIcon
                        icon={showPassword ? eyeOff : eye}
                        slot="end"
                        onClick={handleShowPassword}
                    />
                </IonItem>
                <IonButton expand="full" onClick={onFinish}>Login</IonButton>
                <IonButton expand="full" onClick={() => signInWithGoogle()}>Login with Google</IonButton>
                <p style={{ textAlign: 'center' }}>Forgot your password ? <a href='/reset'>Reset it</a></p>
                <div style={{textAlign:'center',color:'grey',marginTop:'20px'}}>{version}</div>
            </IonContent>
        </IonPage>
    );

}

export default LoginPageComponent;