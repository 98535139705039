import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonItem, IonLabel, IonDatetimeButton, IonDatetime, IonModal, IonInput, IonButton, IonItemDivider, IonRow } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { weightData } from '../../../../utils/types';
import { UserContext } from "../../../../App";
import { getLast7Days, getLast7DaysFullString, getWeightData, writeUserWeight } from "../../Services/dashboardServices";
import { dateToString, getDateRelative, getTodaysDay, stringToDate } from "../../../../utils/misc-utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { LoadingGenie } from "../../../../components/LoadingGenie/LoadingGenie";
import { WeightEntry, addWeightEntry } from "../../../../redux/dashboardSlice";
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend } from 'chart.js';
type weightInputPageProps = {

}

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);

function WeightInputPage(props: weightInputPageProps) {
    const [selectedDate, setSelectedDate] = useState<string>(getTodaysDay());
    const [weight, setWeight] = useState<number>();
    const weightData: Record<string,number> = useSelector((state: RootState) => state.dashboard.weightEntries);
    const interpolatedWeightData = useSelector((state: RootState) => state.dashboard.interpolatedWeightEntries);
    const rollingAverageWeightData = useSelector((state: RootState) => state.dashboard.rollingAverageWeightData);
    const last7daysFullStrings = getLast7DaysFullString();
    const last7daysDisplay = getLast7Days();
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const interpolatedWeightDataArray = last7daysFullStrings.map(date => {
        const weightForDate = interpolatedWeightData[date];
        return weightForDate !== null && weightForDate !== undefined ? weightForDate : null;
    });

    const rollingAverageWeightDataArray = last7daysFullStrings.map(date => {
        const weightForDate = rollingAverageWeightData[date];
        return weightForDate !== null && weightForDate !== undefined ? weightForDate : null;
    });
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);

    const todaysDate = new Date();
    const dispatch = useDispatch();

    const handleDateChange = (e: CustomEvent) => {
        setSelectedDate(e.detail.value!);
    };

    const handleWeightChange = (e: CustomEvent) => {
        setWeight(e.detail.value);
    };

    // useEffect(() => {
    //     console.log('weight data', weightData);
    //     console.log('interpolated weight data', interpolatedWeightData);
    //     console.log('rolling average weight data', rollingAverageWeightData);
    // }, [weightData, interpolatedWeightData, rollingAverageWeightData]);

    function onClickSave() {
        if (user && weight && selectedDate) {
            writeUserWeight(user!, weight!, selectedDate);
            dispatch(addWeightEntry({ date: selectedDate, weight: weight }));
        }
    }


    const dailyWeightDataArray = last7daysFullStrings.map(date => {
        const weightForDate = weightData[date];
        return weightForDate !== undefined ? weightForDate : null;
    });


    useEffect(() => {
        if (canvasRef.current) {
            const chart = new Chart(canvasRef.current, {
                type: 'line',
                data: {
                    labels: last7daysDisplay,
                    datasets: [
                        {
                            label: 'Weight (kg)',
                            data: dailyWeightDataArray,
                            borderColor: 'rgba(75,192,192,0.5)',
                            fill: false
                        },
                        {
                            label: 'Interpolated Weight (kg)',
                            data: interpolatedWeightDataArray,
                            borderColor: 'rgba(128,128,128,0.5)', // Grey color
                            fill: false
                        },
                        {
                            label: '7-Day Rolling Average Weight',
                            data: rollingAverageWeightDataArray,
                            borderColor: 'rgba(255, 105, 180, 1)',
                            borderWidth: 1,
                            fill: false,
                        },
                    ]
                },
                options: {
                    responsive: true,
                    layout: {
                        padding: 10, // Increase padding
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: 'rgb(255, 99, 132)'
                            },
                        },
                    },
                }
            });

            return () => chart.destroy();
        }
    }, [canvasRef, dailyWeightDataArray, interpolatedWeightDataArray, last7daysDisplay]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Weight tracking</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonRow>
                    <canvas ref={canvasRef} style={{ width: "100%" }} />
                    </IonRow>

                <IonItem>
                    <IonLabel>Date</IonLabel>

                    <IonDatetimeButton datetime="datetime" ></IonDatetimeButton>

                    <IonModal keepContentsMounted={true}>
                        <IonDatetime
                            id="datetime"
                            presentation="date"
                            value={selectedDate}
                            onIonChange={handleDateChange}
                            max={dateToString(new Date())}></IonDatetime>
                    </IonModal>
                </IonItem>
                <IonItem>
                    <IonLabel>Weight (kg)</IonLabel>
                    <IonInput type="number" value={weight} onIonInput={handleWeightChange} />
                </IonItem>

                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                    <IonButton onClick={() => onClickSave()} >
                        Save
                    </IonButton>
                </div>
                <IonItemDivider>
                    <IonLabel>Logs</IonLabel>
                </IonItemDivider>

                {Object.entries(weightData).map(([date, weight], index) => (
                    weight ? (
                        <IonItem key={index}>
                            <IonLabel>
                                Date: {date}, Weight: {weight}
                            </IonLabel>
                        </IonItem>
                    ) : null
                ))}
            </IonContent>

        </IonPage >
    )
}

export { WeightInputPage };