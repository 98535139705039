import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRouterOutlet, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useRef, useState } from "react"
import { searchIngredientsUSDAAutocomplete, getUSDAIngredientData } from '../../../../utils/db_connectors/database-connectors';
import { Ingredient, emptyIngredient } from "../../../../utils/types";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { getMacrosFromIngredientList } from "../../../../utils/misc-utils";
import { useDispatch, useSelector } from "react-redux";
import { addIngredient, editIngredient } from "../../../../redux/mealsSlice";
import { RootState } from "../../../../redux/store";
import { initial, replace, set } from "lodash";
import { current } from "@reduxjs/toolkit";
import { RouteComponentProps } from 'react-router-dom';
import { addIngredientToRecipe, editIngredientInRecipe, replaceIngredientInRecipe } from "../../../../redux/recipeSlice";
import { addError } from "../../../../redux/errorSlice";

type StateProps = {
    date?: string,
    mealID?: string,
    fdcID?: string,
    ingredientKey?: string,
    recipeID?: string,
    replacing?: string

}

type editIngredientPageProps = RouteComponentProps<{}, {}, StateProps>


function EditIngredientPage(props: editIngredientPageProps) {
    // var { date, mealID, ingredientID, name } = useParams<{ date: string, mealID: string, ingredientID: string, name: string }>();
    // props.name = decodeURIComponent(props.name);
    const [tempMacros, setTempMacros] = useState({ protein: 0, fat: 0, carbs: 0, calories: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ingredient, setIngredient] = useState<Ingredient>(emptyIngredient);
    const isUserAdmin = useSelector((state: RootState) => state.auth.isAdmin);


    // if (!props.location.state) {
    //     // Handle the case where state is undefined
    //     // This could be a redirect to a default page or showing an error message
    //     return <Redirect to="/mealplan" />;
    // }
    const location = useLocation();
    const state = useSelector((state: RootState) => state);

    const [date, setDate] = useState<string>();
    const [mealID, setMealID] = useState<string>();
    const [ingredientKey, setIngredientKey] = useState<string>();
    const [fdcID, setFdcID] = useState<string>();
    const [recipeID, setRecipeID] = useState<string>();
    const [replacing, setReplacing] = useState<string>();
    const [initialIngredient, setInitialIngredient] = useState<Ingredient | undefined>(undefined);


    useEffect(() => {
        setIngredient(prev => emptyIngredient);
        if (location.state && Object.keys(location.state as object).length !== 0) {
            const { date, mealID, ingredientKey, fdcID, recipeID, replacing } = location.state as StateProps;
            debugger;
            // if ingredientKey, retrieve recipeID from recipeSlice
            setDate(date);
            setMealID(mealID);
            setIngredientKey(ingredientKey);
            setFdcID(fdcID);
            setRecipeID(recipeID);
            setReplacing(replacing);
            history.replace({ state: {} });
            // history.replace(location);
        }
    }, [location])
    // const { date, mealID, ingredientKey, fdcID } = location.state as StateProps;

    const history = useHistory();


    useEffect(() => {
        const findInitialIngredient = () => {
            // Use the 'state' directly from the component scope
            // if its a meal
            debugger;
            if (ingredientKey !== undefined && date !== undefined && mealID !== undefined && state.meals[date] && state.meals[date][mealID] && state.meals[date][mealID].ingredients) {
                return state.meals[date][mealID].ingredients.find((ingredient) => Number(ingredient.key) === Number(ingredientKey));
            }
            // if its a recipe and we're editing an ingredient 
            else if (ingredientKey !== undefined && state.recipe.recipes && state.recipe.recipes['-1']) {
                const viewingRecipe = state.recipe.recipes['-1'].recipe;
                debugger;

                if (fdcID) {
                    return undefined;
                } else {
                    return viewingRecipe.ingredients.find((ingredient) => Number(ingredient.key) === Number(ingredientKey));
                }
            }
            return undefined;
        };
        const ingredient = findInitialIngredient();
        setInitialIngredient(ingredient);
    }, [ingredientKey, date, mealID, fdcID, state]); // Include 'state' in the dependency array


    async function getIngredientInfo(fdcID: string) {
        const res = await getUSDAIngredientData(Number(fdcID));
            // getUSDAIngredientUnits(Number(fdcID))
        debugger;

        return {
            ...emptyIngredient,
            ...res.results
            // proteinPer100g: info.results.protein,
            // carbsPer100g: info.results.carbohydrate,
            // fatPer100g: info.results.fat,
            // caloriesPer100g: info.results.energy,
            // units: unitData.results,
            // name: info.results.name
        }

    }

    useEffect(()=>{
        console.log('fdcid', fdcID);

    },[fdcID]);

    useEffect(() => {
        const initializeIngredient = async () => {
            debugger;
            if (initialIngredient) {
                setIngredient(prev => initialIngredient!);
            } else if (fdcID) {
                var res = await getIngredientInfo(fdcID);
                setIngredient(prev => res);
            }
            setIsLoading(false);
        };
        debugger;
        initializeIngredient();
    }, [initialIngredient, fdcID]);

    const dispatch = useDispatch();

    const handleSelectedUnitChange = (e: any) => {
        // const selectedUnit = ingredient.units.find(unit => unit.key === e.detail.value);
        // if (e.detail.value) {
            setIngredient(prev => ({ ...prev, 'selectedUnit':e.detail.value }));
        // }
    }

    useEffect(() => {
        if (ingredient) {
            setTempMacros(getMacrosFromIngredientList([ingredient]));
        }
    }, [ingredient]);


    function onClickAddIngredient() {
        if (!date) { //editing a recipe
            //dispatch add ingredient recipe
            if (replacing) {
                dispatch(replaceIngredientInRecipe({ key: Number(replacing), ingredient: ingredient }));
                dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: 'Replaced with '+ingredient.name}));

            }
            else if (ingredientKey) { //editing existing  ingredient to recipe
                dispatch(editIngredientInRecipe({ key: Number(ingredientKey), ingredient: ingredient }));
                dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: ingredient.name + ' edited' }));

            }
            else { //adding new ingredient to recipe
                dispatch(addIngredientToRecipe({ ...ingredient, key: (new Date()).getTime() }));
                dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: ingredient.name + ' added' }));

            }

            history.push({
                pathname: '/recipes/singleRecipeView/',
                state: { recipeID: recipeID, usersRecipe: true, editMode: true }
            });
            // state: {recipe: props.recipe, usersRecipe: true, editMode:true}})

            // history.push({ pathname: '/recipes/singleRecipeView' });

        }
        else { //editing a meal 
            if(replacing){
                dispatch(editIngredient({ date: date!, mealID: mealID!, ingredient: {...ingredient, key:Number(replacing) }})); // replace `ingredient` with the actual ingredient data
                dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: ingredient.name + ' added' }));

            }
            else if (replacing === undefined && !ingredientKey) { //adding new ingredient to meal
                dispatch(addIngredient({ date: date!, mealID: mealID!, ingredient: ingredient })); // replace `ingredient` with the actual ingredient data
                // Retrieve the existing list of ingredients from localStorage
                const existingIngredients = JSON.parse(localStorage.getItem('recentIngredients') || '[]');
                // Remove any duplicates of the added ingredient
                const filteredIngredients = existingIngredients.filter((item: any) => item.name !== ingredient.name);
                // Add the new ingredient to the start of the list
                const updatedIngredients = [ingredient, ...filteredIngredients];
                // Store the updated list back in localStorage
                localStorage.setItem('recentIngredients', JSON.stringify(updatedIngredients));
                // history.push({ pathname: '/mealplan' });
                dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: ingredient.name + ' added' }));
            }
            else { //editing ingredient from meal 
                dispatch(editIngredient({ date: date!, mealID: mealID!, ingredient: ingredient })); // replace `ingredient` with the actual ingredient data
                dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: ingredient.name + ' edited' }));

            }
            history.push({ pathname: '/mealplan', state:{date:date} });

        }
    }


    const recipeState = useSelector((state: RootState) => state.recipe); // Access the recipe from the store
    const tempRecipeViewingMode = useSelector((state: RootState) => state.ux.segment);
    const editMode = (tempRecipeViewingMode === 'clean' ? true : recipeState.editMode);

    function onClickReplaceIngredient() {
        history.push({
            pathname: ('/mealplan/add-ingredient'),
            state: { recipeID: recipeID!, replacing: ingredientKey, mealID: mealID, date: date }
        });


    }

    useEffect(()=>{
        console.log('editingredient page ingredient', ingredient);
    },[ingredient])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home" />
                    </IonButtons>
                    <IonTitle>Edit ingredient</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {!isLoading &&
                    <>
                        <IonItem>
                            <IonLabel>Name</IonLabel>
                            <IonInput value={ingredient?.name} class="ion-text-end" slot="end" disabled></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Quantity</IonLabel>
                            <IonInput
                                type="number"
                                placeholder="Quantity"
                                class="ion-text-end"
                                slot="end"
                                value={ingredient?.quantity}
                                onIonInput={e => setIngredient(prev => ({ ...prev, quantity: e.detail.value ? Number(e.detail.value) : 0 }))}
                            />
                        </IonItem>

                        <IonItem>
                            <IonLabel>Unit</IonLabel>
                            <IonSelect
                                placeholder="Unit"
                                onIonChange={e => handleSelectedUnitChange(e)}
                                value={ingredient?.selectedUnit} // Set to the selectedUnit key

                            >
                                {ingredient?.units?.map((unit) => (
                                    <IonSelectOption key={unit.key} value={unit.key}>{unit.modifier}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>

                        <IonItem>
                            <IonLabel>Calories</IonLabel>
                            <IonLabel class="ion-text-end">{tempMacros.calories}</IonLabel>
                        </IonItem>

                        <IonItem>
                            <IonLabel>Protein</IonLabel>
                            <IonLabel class="ion-text-end">{tempMacros.protein}</IonLabel>
                        </IonItem>

                        <IonItem>
                            <IonLabel>Carbohydrates</IonLabel>
                            <IonLabel class="ion-text-end">{tempMacros.carbs}</IonLabel>
                        </IonItem>

                        <IonItem>
                            <IonLabel>Fats</IonLabel>
                            <IonLabel class="ion-text-end">{tempMacros.fat}</IonLabel>
                        </IonItem>
                        <IonButton fill="clear" expand="full" onClick={onClickAddIngredient}>
                            Add Ingredient
                        </IonButton>
                        {((editMode && replacing === undefined) || isUserAdmin || mealID && replacing === undefined) &&
                            <IonButton fill="clear" expand="full" onClick={onClickReplaceIngredient}>
                                Replace Ingredient
                            </IonButton>}
                    </>}
                {isLoading && <IonLabel>Loading...</IonLabel>}
            </IonContent>
        </IonPage >
    )
}

export { EditIngredientPage }

