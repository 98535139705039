import { Middleware, Action, Dispatch, UnknownAction, AnyAction } from 'redux';
import { addIngredient, removeIngredient, editIngredient, deleteRecipe, toggleIngredientLock, addMeal } from './mealsSlice'; // Replace with the actual path to your mealsSlice file
import { writeUserDayOfEating } from '../utils/db_connectors/database-connectors';
import { useContext } from 'react';
import { UserContext } from '../App';
import { useSelector } from 'react-redux';
import { getMacrosFromMeals } from '../utils/misc-utils';
import { RootState } from './store';

export const saveMealMiddleware: Middleware = (storeAPI) => (next) => (action) => {  
  const result = next(action);
  const state = storeAPI.getState();
  if (action && typeof action === 'object') {
    const actionObject = action as { type: string };
    if ('type' in actionObject && typeof actionObject.type === 'string' && actionObject.type.startsWith('meals/') && actionObject.type !== 'meals/fetchMealsSuccess') {
      const meals = state.meals; // Get the updated meals from the store
      Object.keys(meals).forEach(date => {
        const mealList = meals[date];
        if (mealList) {
          const safeMealList = JSON.parse(JSON.stringify(mealList));
          writeUserDayOfEating(state.auth.userUid!, date, safeMealList, getMacrosFromMeals(safeMealList)); // Save the updated meals to the database
        }
      });
    }
  }
  return result; // Return the result of calling next(action)
};