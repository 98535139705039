import { Collapse } from 'antd';
import { useState } from 'react';

import { ErrorMessage, Ingredient, Recipe, emptyIngredient, meal, mealList } from '../../../../utils/types';
import { IonItemSliding, IonItem, IonAvatar, IonLabel, IonItemOptions, IonItemOption, IonIcon, IonNote, IonRow, IonList, IonText, IonCard, IonHeader, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton, IonActionSheet, IonButtons, IonContent, IonModal, IonTitle, IonToolbar, IonInput, IonThumbnail } from '@ionic/react';
import { trash, ellipsisVertical, lockClosed, lockOpen, create } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addIngredient, deleteRecipe, editIngredient, editRecipeName, removeIngredient, toggleIngredientLock } from '../../../../redux/mealsSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useHistory } from 'react-router-dom';
import { saveUsersRecipe } from '../../Services/mealplanServices';
import { getMacrosFromIngredientList } from '../../../../utils/misc-utils';
import { IngredientList } from '../IngredientList/IngredientList';
import { addError } from '../../../../redux/errorSlice';
import { addRecipesToStore, saveRecipe } from '../../../../redux/recipeSlice';
import { ObjectId } from 'bson';


type RecipeCardProps = {
    meal: meal,
    mealID: string,
    date: string
    //setMeals: React.Dispatch<React.SetStateAction<mealList | undefined>>,
    // disableQuantities: boolean,
    // onChangeQuantitiesFunction: (value: number | null, key: number, mealID: string) => void,
    // onClickDeleteRecipe: (mealID: string) => void,
    // lastChanged: { mealID: string | null, ingredientKey: number | null },
    // setLastChanged: React.Dispatch<React.SetStateAction<{ mealID: string | null; ingredientKey: number | null; }>>,
    // restricted: boolean,
}

function RecipeCardFunctionComponent(props: RecipeCardProps) {
    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();
    const [isEditingName, setIsEditingName] = useState(false);
    const [showActionSheet, setShowActionSheet] = useState(false);
    const userUid = useSelector((state: RootState) => state.auth.userUid);


    function setRecipeName(name: string) {
        dispatch(editRecipeName({ date: props.date, mealID: props.mealID, recipeName: name }));
    }
    //retrieve user id from store
    const user = useSelector((state: RootState) => state.auth.userUid);

    const addIngredientToMeal = (newIngredient: Ingredient) => {
        if (props.meal.ingredients.filter(ingredient => ingredient.key === newIngredient.key).length > 0) { //if ingredient is being edited
            dispatch(editIngredient({ date: props.date, mealID: props.mealID, ingredient: newIngredient }));
        }
        else { //if ingredient is being added
            dispatch(addIngredient({ date: props.date, mealID: props.mealID, ingredient: newIngredient }));
        }
    };

    //TODO deplacer recipecard avec setMeals as props
    const onClickLockIngredient = (ingredientKey: number) => {
        dispatch(toggleIngredientLock({ date: props.date, mealID: props.mealID, ingredientKey }));
    }


    const getIngredientFromMeal = (ingredientID: number) => {
        for (let i = 0; i < props.meal.ingredients.length; i++) {
            if (props.meal.ingredients[i].key === ingredientID) {
                return props.meal.ingredients[i];
            }
        }
        return null;
    }


    const onClickDeleteIngredient = (ingredientKey: string) => {
        var ingredientName = getIngredientFromMeal(parseInt(ingredientKey))?.name;
        dispatch(removeIngredient({ date: props.date, mealID: props.mealID, ingredientKey: ingredientKey }));
        dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: ingredientName+' deleted' }));
    }

    const onClickAddIngredient = () => {
        history.push({
            pathname: `/mealplan/add-ingredient/`,
            state: { date: props.date, mealID: props.mealID }
        });
    }


    return (
        <>
            <IonCard>
                <IonCardHeader>
                    <IonItem lines="none">
                    <IonThumbnail slot="start" style={{ width: '64px', height: '64px', borderRadius: '50%', overflow: 'hidden' }}>
                    <img
                                alt={props.meal.recipeName}
                                src={props.meal.imageURL ? props.meal.imageURL : "https://ionicframework.com/docs/img/demos/thumbnail.svg"}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </IonThumbnail>
                        <div style={{ display: 'flex', flexGrow: 1 }}>
                            {isEditingName ? (
                                <IonInput
                                    value={props.meal.recipeName}
                                    onIonInput={e => setRecipeName(e.detail.value!)}
                                    onIonBlur={() => setIsEditingName(false)}
                                    style={isEditingName ? { backgroundColor: '#f3f4f6', border: '1px solid #d1d5db', borderRadius: '5px' } : {}}
                                />
                            ) : (
                                <IonCardTitle>{props.meal.recipeName}</IonCardTitle>
                            )}
                        </div>
                        <IonIcon slot="end" icon={ellipsisVertical} id={"open-action-sheet" + props.mealID} onClick={() => setShowActionSheet(true)} />
                    </IonItem>
                    <IonActionSheet
                        trigger={"open-action-sheet" + props.mealID}
                        header="Actions"
                        buttons={[
                            {
                                text: 'Save recipe',
                                handler: async () => {
                                    //convert meal to recipe
                                    //get macros from ingredients
                                    var temp_macros = getMacrosFromIngredientList(props.meal.ingredients)
                                    var temp_recipe: Recipe = {
                                        ...props.meal,
                                        servings: 1,
                                        proteinPerServing: temp_macros['protein'],
                                        carbsPerServing: temp_macros['carbs'],
                                        fatPerServing: temp_macros['fat'],
                                        caloriesPerServing: temp_macros['calories'],
                                        like: null,
                                        recipeId: new ObjectId().toString(),
                                    };
                                    await dispatch(saveRecipe({ user_id: userUid!, recipe: temp_recipe }));
                                    // var res = await saveUsersRecipe(user!, temp_recipe, dispatch);
                                    // if ('errors' in res && (res.errors as ErrorMessage[]).length > 0) {
                                    //     for (const error of res.errors as ErrorMessage[]) {
                                    //         dispatch(addError({ id: error.id, errorMessage: error.errorMessage }));
                                    //     }
                                    // }
                                    //TODO add recipe to redux store usersRecipes
                                    // dispatch(addRecipesToStore([[temp_recipe], true]));
                                },
                            },
                            {
                                text: 'Edit Name',
                                handler: () => {
                                    setIsEditingName(true);
                                },
                            },
                            {
                                text: 'Delete',
                                role: 'destructive',
                                handler: () => {
                                    dispatch(deleteRecipe({ date: props.date, mealID: props.mealID }));
                                    dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: props.meal.recipeName+' deleted' }));

                                },

                            },
                            {
                                text: 'Cancel',
                                role: 'cancel',
                                data: {
                                    action: 'cancel',
                                },
                            },
                        ]}
                    >

                    </IonActionSheet>
                </IonCardHeader>

                <IngredientList
                    date={props.date}
                    ingredients={props.meal.ingredients}
                    onClickDeleteIngredient={onClickDeleteIngredient}
                    onClickLockIngredient={onClickLockIngredient}
                    onClickAddIngredient={onClickAddIngredient}
                    editable={true}
                    mealID={props.mealID}
                    importing={false}
                />

            </IonCard >

        </>
    )
};


export default RecipeCardFunctionComponent;
