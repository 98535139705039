import { useDispatch, useSelector } from "react-redux";
import { generateMealPlan } from "../../Services/mealplanServices";
import { RootState } from "../../../../redux/store";
import { IonButton, IonCard, IonCardHeader, IonCardTitle, IonItem, IonRow } from "@ionic/react";
import { fetchMealsSuccess } from "../../../../redux/mealsSlice";
import { addError } from "../../../../redux/errorSlice";
import { ErrorMessage } from "../../../../utils/types";

type GenerateMealPlanCardProps = {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setMealPlanned: React.Dispatch<React.SetStateAction<boolean>>;
    date: string;
}

function GenerateMealPlanCard(props: GenerateMealPlanCardProps) {
    const user = useSelector((state: RootState) => state.auth.userUid);
    const dispatch = useDispatch();

    // -----------------------------------------------------------------------------------------------------------------------------------
    // Meal plan generation controls
    // -----------------------------------------------------------------------------------------------------------------------------------
    const onClickGenerateMealPlan = async () => {
        props.setIsLoading(true);
        try {
            const res = await generateMealPlan(user!, [props.date!], dispatch);
            if ('errors' in res && res.errors.length > 0) {
                for (const error of res.errors as ErrorMessage[]) {
                    dispatch(addError({ id: error.id, errorMessage: error.errorMessage }));
                }
                // return;
            }
            if (res.data.length > 0) {
                // Await the dispatch if it returns a Promise. This requires your fetchMealsSuccess action creator to be thunk-based or similar.
                await dispatch(fetchMealsSuccess({ date: props.date, meals: res.data[0] }));
                dispatch(addError({ id: new Date().getTime().toString(), errorMessage: "Successfully generated meal plan" }));
                // Now that the dispatch is complete, set isLoading to false.
            }
            props.setIsLoading(false);

        } catch (error) {
            console.error(error);
            // Handle error here
            props.setIsLoading(false); // Ensure isLoading is set to false even if there's an error.
        }
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IonButton onClick={onClickGenerateMealPlan}>Generate Plan</IonButton>
        </div>        
    )
}

export { GenerateMealPlanCard }