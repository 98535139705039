import { Gesture, IonButton, createGesture } from "@ionic/react";
import { useState, useRef, useEffect } from "react";
import { Recipe } from "../../../../utils/types";
import React from "react";
import { RecipeCard } from "./RecipeCard";

type recipeCardWrapperProps = {
    recipeStack: Recipe[],
    onSwipeRight: (recipeId: string) => void,
    onSwipeLeft: (recipeId: string) => void,
}

function RecipeCardWrapper(props: recipeCardWrapperProps) {

    // const [recipeStack, setRecipeStack] = useState<Recipe[]>([]);
    // const cardRefs = recipeStack.map(() => React.createRef<HTMLDivElement>());
    const cardRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);
    cardRefs.current = props.recipeStack.map(() => React.createRef<HTMLDivElement>());

    const isSwipingRef = useRef(false);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);


    useEffect(() => {

        if (props.recipeStack.length === 0) return;

        const element = cardRefs.current[currentCardIndex]?.current;
        let gesture: Gesture | null = null;

        if (element) {
            gesture = createGesture({
                el: element,
                gestureName: 'swipe',
                onStart: () => {
                    isSwipingRef.current = true;
                },
                onMove: (detail) => {
                    if (!isSwipingRef.current) return;

                    element.style.transform = `translateX(${detail.deltaX}px) rotate(${detail.deltaX / 10}deg)`;
                    element.style.opacity = `${0.7 + (0.8 - Math.abs(detail.deltaX) / 150)}`;

                },
                onEnd: (detail) => {
                    if (Math.abs(detail.deltaX) > 150) {
                        element.style.transition = '.5s ease-out';
                        element.style.transform = `translateX(${detail.deltaX > 0 ? window.innerWidth : -window.innerWidth}px) rotate(${detail.deltaX / 10}deg)`;
                        element.style.opacity = '0';
                        setCurrentCardIndex((prevIndex) => (prevIndex + 1) % props.recipeStack.length);
                        if (detail.deltaX > 0) {
                            // if (user !== null) {
                            props.onSwipeRight(props.recipeStack[currentCardIndex].recipeId);
                            // props.setOnboardingRecipeLikes(prev => [...prev, { recipeId: props.recipeStack[currentCardIndex].recipeId, liked: true }])
                            // likeRecipe(user?.uid, recipeStack[currentCardIndex].recipeId, true);
                            // }
                        } else {
                            // if (user !== null) {
                            props.onSwipeLeft(props.recipeStack[currentCardIndex].recipeId);
                            // props.setOnboardingRecipeLikes(prev => [...prev, { recipeId: recipeStack[currentCardIndex].recipeId, liked: false }])
                            // }
                        }
                    } else {
                        element.style.transition = '.5s ease-out';
                        element.style.transform = `translateX(0px) rotate(0deg)`;
                        element.style.opacity = '1';
                    }
                }
            });

            gesture.enable();
        }

        return () => {
            if (gesture) {
                gesture.destroy();
                gesture = null;
            }
        }
    }, [currentCardIndex, cardRefs, props.recipeStack]);


    return (
        <div style={{ display:'flex',flexDirection:'column',}}>
            <div style={{height:'calc(100svh - 180px)', }}>

                {props.recipeStack.map((recipe, index) => (
                    <div
                        key={index}
                        ref={cardRefs.current[index]}
                        className="card"
                        style={{ zIndex: props.recipeStack.length - index }}
                    >
                        <RecipeCard recipe={recipe} />
                    </div>
                ))}
                </div>
            <div style={{
                // display: 'flex',
                justifyContent: 'center',
                textAlign:'center',
                // gap: '20px',
                // padding: '10px',
                // backgroundColor: '#fff', // Example background color
                zIndex: 2000,
                // position: 'absolute',
                // bottom:'54px',
                left:0,
                right:0,
                marginBottom:'10px',
                
                // marginTop:'85vh'
            }}>
                <IonButton>Dislike</IonButton>
                <IonButton>Like</IonButton>
            </div>
    </div>
    )
};

export { RecipeCardWrapper };