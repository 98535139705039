import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonChip, IonRow } from "@ionic/react"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../redux/store";
import { mealList } from "../../../../utils/types";
import { getDailyMacroAverage, loadMeals } from "../../../MealPlan/MealPlanUtils";

type averageCaloricInputCardProps = {
  // sevenDayAverage: number 

}

function AverageCaloricInputCard(props: averageCaloricInputCardProps) {

  const mealListStore = useSelector((state: RootState) => state.meals);
  const [period, setPeriod] = useState<number>(7);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dateTo = new Date()
  const user = useSelector((state: RootState) => state.auth.userUid);
  const dispatch = useDispatch();

  const onClickDurationChip = (newValue: number) => {
    setPeriod(newValue);
  }

  const [filteredMealList, setFilteredMealList] = useState<{ [date: string]: mealList }>({});
  const [averageCalories, setAverageCalories] = useState<number>(0);


  useEffect(() => {
    if (user) {
      setIsLoading(true);
      const dateFrom = new Date();
      dateFrom.setDate(dateFrom.getDate() - period);
      loadMeals(dateFrom, dateTo, mealListStore, dispatch, user);
      setIsLoading(false);
    }
  }, [period, mealListStore, user]);

  useEffect(() => {
    if (isLoading || !user) return;

    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - period);

    const filteredList = Object.keys(mealListStore).reduce((acc, date) => {
      const mealList = mealListStore[date];
      const mealDate = new Date(date);

      if (mealDate >= startDate && mealDate <= currentDate) {
        acc[date] = mealList;
      }

      return acc;
    }, {} as { [date: string]: mealList });

    setFilteredMealList(filteredList);

    // Compute average calories
    const averageCalories = getDailyMacroAverage(filteredList).calories;
    setAverageCalories(averageCalories);

  }, [period, mealListStore, isLoading, user]);

  return (
    <IonCard >
      <IonCardContent>
        {isLoading ? <h1>Loading...</h1> : <>
        <h1>{averageCalories}</h1> daily caloric average</>}
        
          <IonRow style={{justifyContent:"center"}}>
            <IonChip outline={period == 7} onClick={() => onClickDurationChip(7)}>7 days</IonChip>
            <IonChip outline={period == 14} onClick={() => onClickDurationChip(14)}>14 days</IonChip>
            <IonChip outline={period == 30} onClick={() => onClickDurationChip(30)}>1 M</IonChip>
        </IonRow>

        {/* {props.sevenDayAverage} */}
      </IonCardContent>
    </IonCard>
  )
}

export { AverageCaloricInputCard }