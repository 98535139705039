import { IonRow, IonDatetimeButton, IonModal, IonDatetime } from "@ionic/react";
import { Button, Row, Col } from "antd";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";
import { FabButton } from "./FabButton/FabButton";
import { GenerateMealPlanCard } from "./GenerateMealPlanCard/GenerateMealPlanCard";
import NutritionDetailsCard from "./NutritionDetailsCard/NutritionDetailsCard";
import RecipeCardFunctionComponent from "./RecipeCard/RecipeCard";
import { dateToString, getMacrosFromMeals, getTodaysDay } from "../../../utils/misc-utils";
import { useEffect, useState } from "react";
import { mealList, sumMacros } from "../../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { addMeal, fetchMealsSuccess } from "../../../redux/mealsSlice";
import { RootState } from "../../../redux/store";
import { loadUserDayOfEating } from "../../../utils/db_connectors/database-connectors";
import { useHistory, useLocation } from "react-router";
import { setDayViewDate } from "../../../redux/uxSlice";

type MealPlanDayViewProps = {

}

function MealPlanDayView(props: MealPlanDayViewProps) {
    // const [date, setDate] = useState<string>(getTodaysDay());
    const date = useSelector((state: RootState) => state.ux.dayViewDate);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sumMacros, setSumMacros] = useState<sumMacros>({ calories: 0, protein: 0, carbs: 0, fat: 0 });
    const user = useSelector((state: RootState) => state.auth.userUid);
    const dispatch = useDispatch();
    const meals: mealList | undefined = useSelector((state: RootState) => state.meals[date]);
    const [mealPlanned, setMealPlanned] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();
    // const { param_day } = useParams<{ param_day: string }>();
    // var date = dayjs(param_day).toDate();

    useEffect(() => {
        if (location.state && Object.keys(location.state as object).length > 0) {
            const { date } = location.state as { date: string};
            dispatch(setDayViewDate(date));
            // setDate(date);
            // setRecipe(recipe);
            history.replace({ state: {} });
            setIsLoading(prev => false);
        }
    }, [location]);

    // useEffect function to initialize day state variable with param_day or today's date
    useEffect(() => {
        if (user) {
            if (meals === undefined) {
                setIsLoading(true);
                // setIsLoading(true);
                getMeals().then((mealsFromDB) => {
                    if (mealsFromDB) {
                        dispatch(fetchMealsSuccess({ date: date, meals: mealsFromDB }))
                    }
                    else {
                        dispatch(fetchMealsSuccess({ date: date, meals: {} }))
                    }
                    setIsLoading(false);
                })
        }

            // if (meals === undefined ) { //if nothing is found in redux store
            //     setIsLoading(true);
            //     getMeals().then((mealsFromDB) => {
            //         debugger;
            //         if (mealsFromDB) { // if something is found in db then set it in redux store
            //             dispatch(fetchMealsSuccess({ date: date, meals: mealsFromDB }))
            //         }
            //         else {
            //             dispatch(fetchMealsSuccess({ date: date, meals: {} }))
            //         }
            //         setIsLoading(false);
            //     })
            // }
        }
    }, [user, date, dispatch]);

    useEffect(() => {
        var temp = getMacrosFromMeals(meals);
        setSumMacros(prev => temp);
        // if (!isInitialLoading && date !== undefined && meals !== undefined && Object.keys(meals).length !==0) {
        //     writeUserDayOfEating(props.user_id, date, meals, temp); //temp and not sumMacros so it waits for the macros to get computed
        // }
    }, [meals])
    
    const getMeals = async () => {
        setIsLoading(true);
        if (user != null && date !== null) {
            const res = await loadUserDayOfEating(user, [date]);
            if(res && date in res){
                var temp = res[date];
                if (temp && 'recipes' in temp) {
                    setIsLoading(false);
                    return temp['recipes'];
                }
            }
            else{
                setIsLoading(false);
                return {};
            }
        }
        setIsLoading(false);
    }

    function getDayAfter(day: string) {
        // Parse the string back into a Date object
        var date = new Date(day);
        date.setDate(date.getDate() + 1);
        const formattedDate = dateToString(date);
        dispatch(setDayViewDate(formattedDate));
        // setDate(formattedDate);
        // setDate(prev => formattedDate);
    }

    function getDayBefore(day: string) {
        // Parse the string back into a Date object
        var date = new Date(day);
        date.setDate(date.getDate() - 1);
        const formattedDate = dateToString(date);
        dispatch(setDayViewDate(formattedDate));
        // setDate(formattedDate);

        // setDate(prev => formattedDate);
    }

    const onClickAddEmptyRecipe = () => {
        if (user) {
            var newMealRank = 0;
            if (meals !== undefined) {
                for (let key in meals) {
                    newMealRank = meals[key]['mealRank'] > newMealRank ? meals[key]['mealRank'] : newMealRank;
                }
                newMealRank = newMealRank + 1;
            }
            var newMeal = {
                ingredients: [],
                recipeId: '',
                mealName: '',
                mealRank: newMealRank,
                recipeName: 'New Recipe',
                source: '',
                sourceURL: ''
            }
            var newMealKey = (new Date()).getTime().toString();
            dispatch(addMeal({ date: date, mealID: newMealKey, meal: newMeal }));
        }
        else {
            //prompt signup modal
        }
    };

    
    return (
        <>
            <IonRow class="ion-justify-content-center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Button onClick={() => getDayBefore(date)}>{'<'}</Button>
                <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime
                        id="datetime"
                        presentation="date"
                        value={date}
                        onIonChange={(e) => dispatch(setDayViewDate(e.detail.value! as string))}
                    ></IonDatetime>                        </IonModal>
                <Button onClick={() => getDayAfter(date)}>{'>'}</Button>
            </IonRow>

            {isLoading ? <LoadingGenie /> :
                <>

                    <NutritionDetailsCard
                        key={'nutritionCard'}
                        sumMacros={sumMacros}
                        isAverageCard={false}
                    />

                    {meals !== undefined && Object.entries(meals).length > 0
                        && Object.entries(meals).map(meal => {
                            return (
                                <Row justify="center" style={{ marginBottom: '20px' }}>
                                    <Col lg={14} md={18} sm={23} xs={23} key={"colMeal" + meal[0]} >
                                        <RecipeCardFunctionComponent
                                            key={"rcpCard" + meal[0]}
                                            mealID={meal[0]}
                                            meal={meal[1]}
                                            date={date!}
                                        />

                                    </Col>
                                </Row>
                            );
                        })
                    }
                </>
            }



            <GenerateMealPlanCard setIsLoading={setIsLoading} setMealPlanned={setMealPlanned} date={date} />
            <FabButton date={date} onClickAddEmptyRecipe={onClickAddEmptyRecipe} />
        </> 
    )
}

export { MealPlanDayView };