import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonReorderGroup, IonItem, IonSelect, IonSelectOption, IonButton, IonReorder, ItemReorderEventDetail } from "@ionic/react"
import { mealType } from "../../../../utils/types";
import { useEffect, useState } from "react";


type DoeStructureComponentProps = {
    mealList: mealType[],
    setMealList: (mealList: mealType[]) => void;
}

function DoeStructureComponent(props:DoeStructureComponentProps) {

    const [lastKey, setLastKey] = useState<number>(0);

    useEffect(()=>{
        // get maximum of key value in initialState
        if(props.mealList.length === 0) return;
        const maxKey = Math.max(...props.mealList.map(meal => meal.key));
        setLastKey(maxKey);
    },[props.mealList])

    function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
        const { detail } = event;
        const updatedMeals = detail.complete(props.mealList);
        props.setMealList(updatedMeals);
    }

    function onChangeMealType(key: number, type: string) {
        const newMealList = props.mealList.map(meal => {
            if (meal.key === key) {
                return { ...meal, type: type };
            }
            return meal;
        });
        props.setMealList(newMealList);
    }

    function onClickAddMeal(){
        const newMeal = { key: lastKey+1, type: 'Meal' };
        setLastKey(prev => prev +1);

        props.setMealList([...props.mealList, newMeal]);
    }

    function onClickDeleteMeal(key: number) {
        props.setMealList(props.mealList.filter(meal => meal.key !== key));
    }

    return (
        <>
            <IonList>
                <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>


                    {props.mealList && props.mealList.map(meal => {
                        return (
                            <IonItem key={meal.key}>
                                <IonSelect
                                    value={meal.type}
                                    onIonChange={e => onChangeMealType(meal.key, e.detail.value)}>
                                    <IonSelectOption value="Breakfast">Breakfast</IonSelectOption>
                                    <IonSelectOption value="Meal">Meal</IonSelectOption>
                                    <IonSelectOption value="Snack">Snack or Dessert</IonSelectOption>
                                </IonSelect>
                                {/* <IonLabel>{meal.type}</IonLabel> */}
                                <IonButton fill='clear' onClick={() => onClickDeleteMeal(meal.key)} slot="end">Delete</IonButton>
                                <IonReorder slot='end'></IonReorder>
                            </IonItem>)
                    })}
                </IonReorderGroup>
            </IonList>
            <IonButton fill="clear" onClick={onClickAddMeal}>Add meal</IonButton>

            {/* <p>Macros: {props.userData.macrosTarget}</p> */}
            
        </>
    )
}

export { DoeStructureComponent}