// import { BrowserRouter as Router, Routes, Route, useParams, Link } from '@ionic/react-router';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';


import 'antd/dist/reset.css';
import './assets/App.css';
import ReactGA from 'react-ga4';

import LoginPageComponent from './features/loginPage';
import { ProfilePageComponent } from './features/Settings/Components/ProfilePage/profilePage';
import { SupportPageComponent } from './features/Settings/Components/SupportPage/support';
import './assets/CustomStyles.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Loading } from './components/Loading/Loading';
import { MealPlanPage } from './features/MealPlan/Components/MealPlanPage';
import { createContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import { isUserAdmin, isUserPremium } from './utils/stripe_connectors/isUserPremium';

//IONIC STUFF
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { User } from 'firebase/auth';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { setupIonicReact } from '@ionic/react';
import { SettingsMenuComponent } from './features/Settings/Components/SettingsMenu/SettingsMenuComponent';

import { register } from 'swiper/element/bundle';
import { auth } from './utils/firebase';
import { OnboardingUserFormData, RecipeLike } from './utils/types';

import { getUserGoal, saveRecipeToDatabase, saveUserGoal, updateRecipeLikes } from './utils/db_connectors/database-connectors';
import { OnBoardingSlidesComponent } from './features/Onboarding/Components/Slides/onBoardingSlides';
import { getTodaysDay } from './utils/misc-utils';
import { DashboardPageComponent } from './features/DashboardPage/Components/dashboardPage';
import { MacrosPageComponent } from './features/Settings/Components/MacrosPage/macrosPage';
import { generateMealPlan } from './features/MealPlan/Services/mealplanServices';
import { WeightInputPage } from './features/DashboardPage/Components/WeightTrackingCard/weightInputPage';
import { RecipePage } from './features/RecipePage/RecipePage';
import Provider from './redux/Provider';
import { useDispatch, useSelector } from 'react-redux';
import { setEmail, setIsAdmin, setIsPremium, setProviderId, setUser, setUserGoal } from './redux/authSlice';
import { RootState } from './redux/store';
import { set } from 'lodash';
import { EditIngredientPage } from './features/MealPlan/Components/EditIngredientModal/EditIngredientPage';
import { EditRecipeServingPage } from './features/MealPlan/Components/AddIngredientModal/EditRecipeServing';
import { AddIngredientPage } from './features/MealPlan/Components/AddIngredientModal/AddIngredient';
import { AddRecipePage } from './features/MealPlan/Components/AddIngredientModal/AddRecipePage';
import { on } from 'events';
import { SingleRecipeView } from './features/RecipePage/components/SingleRecipeView';
import { ResetPwdPageComponent } from './features/resetPwdPage';
import { analyticsOutline, calendarOutline, pizzaOutline, settingsOutline, cartOutline} from 'ionicons/icons';
import { MealStructurePage } from './features/Settings/Components/MealStructurePage/mealStructurePage';
import { ImportRecipePage } from './features/MealPlan/Components/ImportRecipe/ImportRecipePage';
import { ErrorDisplay } from './features/MealPlan/Components/ErrorDisplay/ErrorDisplay';
// import { RetakeOnboarding } from './features/RetakeOnboarding/retakeOnboarding.tsx.old';
import { GroceryListPage } from './features/GroceryList/groceryListPage';
import { OnBoarding2 } from './features/Onboarding/Components/Slides/onBoarding2';

register();
// import { Helmet } from 'react-helmet-async';


// import GetId from './pages/dayOfEatingPage';

const TRACKING_ID = "G-J2L0FB9F9Y"
ReactGA.initialize(TRACKING_ID);

type UserContextType = {
  user: User | null;
  isPremium: boolean;
  isAdmin: boolean;
};

export const UserContext = createContext<UserContextType>({ user: null, isPremium: false, isAdmin: false });


setupIonicReact();

function App() {
  // const [user, loading, error] = useAuthState(auth);
  // // const [userN, loadingN, errorN] = useCustomNativeAuthState();
  // const [isPremium, setIsPremium] = useState<boolean>(false);
  // const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, loading, error] = useAuthState(auth);

  // const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  // const user = useSelector((state: RootState) => state.auth.user);
  const isPremium = useSelector((state: RootState) => state.auth.isPremium);
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);

  const [userIsOnboarding, setUserIsOnboarding] = useState<boolean>(false);
  const [onboardingRecipeLikes, setOnboardingRecipeLikes] = useState<RecipeLike[]>([]);  // const [onboardingFormData, setOnboardingFormData] = useState<UserFormData>();
  const [key, setKey] = useState<number>(0);
  const [onboardingFormData, setOnboardingFormData] = useState<OnboardingUserFormData>({
    username: '',
    birthdate: '',
    gender: '',
    height: 0,
    weight: 0,
    activityLevel: '',
    goal: '',
    caloricMaintenance: 0,
    caloricTarget: 0,
    macrosTarget: {
      protein: 0,
      carbs: 0,
      fat: 0
    },
    meals: [{key:0, type:'Breakfast'}, {key:1, type:'Meal'},{key:2, type:'Meal'}]
    // date: new Date().toISOString(),
  });


  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const premiumResult = await isUserPremium();
        dispatch(setIsPremium(premiumResult));
        const adminResult = await isUserAdmin();
        dispatch(setIsAdmin(adminResult));
        dispatch(setUser(user.uid));
        // dispatch(setIsAdmin(isAdmin));
        // dispatch(setIsPremium(isPremium));
        dispatch(setEmail(user.email));
        dispatch(setProviderId(user.providerId));
        // Retrieve UserGoal and dispatch to auth slice
        const userGoal = await getUserGoal(user.uid, dispatch);
        dispatch(setUserGoal(userGoal));
      }
    };

    fetchData();
  }, [dispatch, user]);


  useEffect(() => {
    if (user && userIsOnboarding) {
      updateRecipeLikes(user.uid, onboardingRecipeLikes);
      saveUserGoal(user.uid, onboardingFormData);
      setUserIsOnboarding(false);
      setOnboardingRecipeLikes(prev => []);
      // generateMealPlan(user.uid, getTodaysDay());
    }
  }, [userIsOnboarding, user]);


  return (
    <>
      {/* // <UserContext.Provider value={userContextValues}> */}
    <IonApp>
        <ErrorDisplay />

      {loading ? (
        <Loading />
      ) : user ? (

        <IonReactRouter>
          <IonTabs >
            <IonRouterOutlet>
              <Route
                path="/mealplan/add-ingredient/"
                component={AddIngredientPage}
                exact={true}
              />
              <Route
                path="/mealplan/add-recipe/"
                component={AddRecipePage}
                exact={true}
              />
              <Route
                path="/mealplan/import-recipe/"
                component={ImportRecipePage}
                exact={true}
              />
              <Route
                path="/mealplan/finalize-recipe-import/"
                component={SingleRecipeView}
                exact={true}
              />
              <Route
                path="/mealplan/editIngredient/"
                exact={true}
                component={EditIngredientPage}
              />
              <Route
                path="/mealplan/editRecipe/"
                exact={true}
                component={EditRecipeServingPage}
              />
              <Route exact={true} path="/mealplan"
                render={() => <MealPlanPage user_id={user.uid} day={null} userData={onboardingFormData} />}
              />



              <Route exact path="/dashboard/weightInput" render={() => <WeightInputPage />} />
              <Route exact path="/dashboard" render={() => <DashboardPageComponent key={key} />} />

              <Route exact path="/recipes" render={() => <RecipePage />} />
              <Route exact path="/recipes/singleRecipeView/" component={SingleRecipeView} />
              <Route exact path="/recipes/singleRecipeView/add-ingredient" component={AddIngredientPage} />
              {/* <Route exact path="recipes/singleRecipeView/editIngredient" component={EditIngredientPage} /> */}
              <Route exact path="/groceries" component={GroceryListPage} />
              {/* <Route exact path="/recipes/" */}
              <Route exact path="/settings/profile" component={ProfilePageComponent} />
              <Route exact path="/settings/macros" component={MacrosPageComponent} />
              <Route exact path="/settings/doe" component={MealStructurePage} />
              {/* <Route exact path="/settings/onboarding" render={() => <RetakeOnboarding/>} /> */}


              <Route exact path="/settings/support" component={SupportPageComponent} />
              <Route exact path="/settings" component={SettingsMenuComponent} />

              <Route render={() => <Redirect to='/mealplan' />} />


            </IonRouterOutlet>

            <IonTabBar slot="bottom" id="tabs" style={{paddingBottom:'10px'}}>
              <IonTabButton tab="dashboard" href="/dashboard" onClick={() => setKey(prevKey => prevKey + 1)}>
                <IonIcon icon={analyticsOutline} />
                <IonLabel>Dashboard</IonLabel>
              </IonTabButton>
              <IonTabButton tab="mealplan" href="/mealplan">
                <IonIcon icon={calendarOutline} />
                <IonLabel>Meal plan</IonLabel>
              </IonTabButton>
              <IonTabButton tab="groceries" href="/groceries">
                <IonIcon icon={cartOutline} />
                <IonLabel>Groceries</IonLabel>
              </IonTabButton>
              <IonTabButton tab="recipes" href="/recipes">
                <IonIcon icon={pizzaOutline} />
                <IonLabel>Recipes</IonLabel>
              </IonTabButton>

              {/* <IonTabButton> </IonTabButton> */}
              <IonTabButton tab="settings" href="/settings">
                <IonIcon icon={settingsOutline} />
                <IonLabel>Settings</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>


      ) : (
        <IonReactRouter>
          {/* <Route path="/" render={() => <Redirect to="/onboarding" />} exact /> */}
          <Route exact path="/login" component={LoginPageComponent} />
          <Route exact path="/reset" component={ResetPwdPageComponent} />
          {/* <Route exact path="/doestructure" component={DoeStructureComponent}/> */}

          <Route exact path="/settings" render={() => <Redirect to="/" />} />
          {/* <Route exact path="/signup" render={()=> <SignupPageComponent isUserOnboarding={false} setIsUserOnboarding={setUserIsOnboarding}/>} /> */}
          <Route
            exact
            path="/"
            render={() =>
              <OnBoarding2
                onboardingFormData={onboardingFormData}
                setOnboardingFormData={setOnboardingFormData}
                setUserIsOnboarding={setUserIsOnboarding}
                setOnboardingRecipeLikes={setOnboardingRecipeLikes}
                retaking={false}
              />}
          />

          {/* <Route render={() => <Redirect to='/' />} /> */}
        </IonReactRouter>
      )}
    </IonApp>
      {/* //   </UserContext.Provider > */}
    </>
    // </Provider>
  );

}

export default App;
