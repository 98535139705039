import backendUrl from "../../../utils/api_connectors/backend";
import { SpecificMacroData } from "../Components/dashboardPage";

// async function getDailyMacrosData(user_id:string, date_from:string, date_to:string){
//     const requestOptions = {
//         method: 'POST',
//         headers: { "content-type": "application/json" },
//         body: JSON.stringify({ user_id: user_id, date_from: date_from, date_to: date_to, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
//     };
//     const response = await fetch(backendUrl + 'loadDailyMacrosData', requestOptions);
//     var data = await response.json();
//     return data;
// }

function getMeanCaloricInput(dailyCaloricData: SpecificMacroData[]) {
    const filteredNumbers = dailyCaloricData
        .filter((data): data is SpecificMacroData => data.value !== null)
        .map(data => data.value as number);
    const sum = filteredNumbers.reduce((a, b) => a + b, 0);
    const mean = sum / filteredNumbers.length;
    return Math.round(mean);
}

function getLast7DaysFullString() {
    const dates = [...Array(7)].map((_, i) => {
        const d = new Date();
        d.setDate(d.getDate() - i);
        return d.toISOString().split('T')[0];
    });
    return dates.reverse();
}


function getLast7Days() {
    const dates = '0123456'.split('').map(day => {
        const tempDate = new Date();
        tempDate.setDate(tempDate.getDate() - Number(day));
        return tempDate.getDate() + '/' + (tempDate.getMonth() + 1);
    });
    return dates.reverse();
}

async function getWeightData(user_id:string, date_from:string, date_to:string){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id, date_from: date_from, date_to: date_to, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone})
    };
    const response = await fetch(backendUrl + 'loadUserWeight', requestOptions);
    var data = await response.json();
    return data;
}

async function writeUserWeight(user_id:string, weight:number, date:string){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id, weight: Number(weight), date: date, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
    };
    const response = await fetch(backendUrl + 'writeUserWeight', requestOptions);
    var data = await response.json();
    return data;
}

export { getMeanCaloricInput, getLast7Days, getWeightData, writeUserWeight, getLast7DaysFullString}