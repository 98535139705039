import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { CategoryScale, Chart, LineController, LineElement, LinearScale, PointElement } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { DailyCaloricInputCard } from "./DailyCaloricInputCard/DailyCaloricInputCard";
import { UserContext } from "../../../App";
import { getTodaysDay, dateToString, getDateRelative } from "../../../utils/misc-utils";
import { getMeanCaloricInput, getWeightData } from "../Services/dashboardServices";
import { loadUserGoal } from "../../../utils/db_connectors/database-connectors";
import { CaloricTargetCard } from "./CaloricTargetCard/CaloricTargetCard";
import './dashboardPage.css';
import { WeightTrackingCardComponent } from "./WeightTrackingCard/weightTrackingCard";
import { weightData } from "../../../utils/types";
import { Loading } from "../../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";
import { WeightEntry, addWeightEntry, getWeightEntries } from "../../../redux/dashboardSlice";
import { AverageCaloricInputCard } from "./AverageCaloricInputCard/AverageCaloricInputCard";
import { OnTrackCard } from "./OnTrackCard/OnTrackCard";

export type SpecificMacroData = {
    date: string,
    value: number | null
};

type MacrosData = {
    date: string,
    macros: {
        calories: number,
        protein: number,
        carbs: number,
        fat: number
    } | null
}


type dashboardPageComponentProps = {
    key: number
}

function DashboardPageComponent(props: dashboardPageComponentProps) {
    // const [dailyMacrosData, setDailyMacrosData] = useState<MacrosData[]>([]);
    // const [dailyCaloricData, setDailyCaloricData] = useState<SpecificMacroData[]>([]); // [calories, protein, carbs, fat
    // const [userGoals, setUserGoals] = useState<any>({});
    // // const [weightData, setWeightData] = useState<weightData[]>(); // [date, weight
    // // const weightData: Record<string,number> = useSelector((state: RootState) => state.dashboard.weightEntries);
    const dispatch = useDispatch<AppDispatch>();
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const loadedMeals = useSelector((state: RootState) => state.meals);
    const weightEntries = useSelector((state: RootState) => state.dashboard.weightEntries);
    const rollingAverageWeightEntries = useSelector((state: RootState) => state.dashboard.rollingAverageWeightData);
    const weightDataLoadingStatus = useSelector((state: RootState) => state.dashboard.weightDataLoadingStatus);

    const date14DaysAgo = dateToString(getDateRelative(-14));

    useEffect(() => {
        if (weightDataLoadingStatus === 'idle') {
            dispatch(getWeightEntries({ dateFrom: date14DaysAgo }));
        }
    }, [dispatch, date14DaysAgo, weightDataLoadingStatus]);

    useEffect(()=>{
        console.log('weight entries', weightEntries);
    },[weightEntries])

    // // const { user, isPremium, isAdmin } = useContext(UserContext);
    // const user = useSelector((state: RootState) => state.auth.userUid);

    // var todaysDate = getTodaysDay();
    // var sevendaysago = dateToString(getDateRelative(-7));
    // const [sevenDayCaloricInput, setSevenDayCaloricInput] = useState<number>(0);
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     const loadData = async () => {
    //         setIsLoading(true);
    //         try {
    //             await Promise.all([
    //                 getMacrosData(),
    //                 loadUserGoal(user!, dispatch).then((data) => {
    //                     setUserGoals(data);
    //                 }),
    //             ]);
    //         } catch (error) {
    //             console.error('Failed to fetch data:', error);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     loadData();
    // }, [dispatch, user]);


    // async function getMacrosData() {
    //     try {
    //         const temp = await getDailyMacrosData(user!, sevendaysago, todaysDate);
    //         setDailyMacrosData(temp);
    //         const caloriesData = getSpecificMacroData(temp, 'calories');
    //         setDailyCaloricData(caloriesData);
    //         const averageCalories = caloriesData.reduce((sum, entry) => sum + (entry.value ?? 0), 0) / caloriesData.length;
    //         setSevenDayCaloricInput(averageCalories);
    //     } catch (error) {
    //         console.error('Failed to fetch macros data:', error);
    //     }
    // }


    // function getSpecificMacroData(macrosData: MacrosData[], macro: string) {
    //     return macrosData.map(({ date, macros }) => ({
    //         date,
    //         value: macros ? macros[macro as keyof MacrosData['macros']] : null
    //     }));
    // }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Dashboard</IonTitle>
                </IonToolbar>
            </IonHeader>
            {isLoading ? <IonContent><LoadingGenie /></IonContent> :
                <IonContent>
                    <IonRow>
                        <IonCol>
                            {/* <CaloricTargetCard caloricTarget={userGoals?.caloricTarget} /> */}
                        </IonCol>
                        <IonCol>
                            {/* <SevenDayCaloricInputAverageCard
                                sevenDayAverage={sevenDayCaloricInput}
                            /> */}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {/* <DailyCaloricInputCard
                                dailyCaloricData={dailyCaloricData}
                                caloricTarget={userGoals?.caloricTarget}
                                sevenDayCalorieInputAverage={sevenDayCaloricInput}
                            /> */}
                        </IonCol>
                    </IonRow>

                    <OnTrackCard/>

                    <WeightTrackingCardComponent />

                    {/* {weightData ?
                                <WeightTrackingCardComponent weightData={weightData} /> :
                                <></>
                            } */}

                    <AverageCaloricInputCard />
                </IonContent>
            }
        </IonPage>
    );
}

export { DashboardPageComponent };