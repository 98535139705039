
import { IonPage, IonHeader, IonToolbar, IonSegment, IonSegmentButton, IonContent, IonButtons, IonTitle } from "@ionic/react";
import { useState } from "react";
import { OnboardingUserFormData } from "../../../utils/types";
import { MealPlanDayView } from "./MealPlanDayView";
import { MealPlanWeekView } from "./MealPlanWeekView";
import { MealPlanCustomView } from "./MealPlanCustomView";

type mealPlanPageProps = {
    user_id: string,
    day: string | null,
    userData: OnboardingUserFormData,
}



function MealPlanPage(props: mealPlanPageProps) {
    // const [meals, setMeals] = useState<mealList>();
    const [segmentValue,setSegmentValue] = useState<string>("day");

  
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <h3>Meal Plan</h3> */}
                        <IonTitle>Meal Plan</IonTitle>
                        <IonButtons slot="end">
                        <IonSegment scrollable={true} value={segmentValue}
                            onIonChange={(e) => setSegmentValue(e.detail.value! as string)}
                            style={{ marginRight: 0, marginTop: 10, marginBottom: 10 }}>
                            <IonSegmentButton value="custom">Week</IonSegmentButton>
                            {/* <IonSegmentButton value="week">Week</IonSegmentButton> */}
                            <IonSegmentButton value="day">Day</IonSegmentButton>
                        </IonSegment>
                        </IonButtons>
                    {/* </div> */}
                </IonToolbar>
            </IonHeader>

            <IonContent>
                {segmentValue === "day" &&
                    <MealPlanDayView />
                }

                {/* {segmentValue === "week" &&
                    <MealPlanWeekView />
                } */}

                {segmentValue === "custom" &&
                <MealPlanCustomView/>}

            </IonContent>
        </IonPage>

    )

}

export { MealPlanPage };
