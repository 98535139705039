export type unitType = {
    amount: number,
    gram_weight: number,
    modifier: string,
    key: number
};

export var emptyUnit = {
    amount: 0,
    gram_weight: 0,
    modifier: '',
    key: -1
};

export interface Ingredient {
    key: number;
    // fdc_id:number;
    name: string;
    foundName?: string;
    quantity?: number;
    units: { amount: number, gram_weight: number, modifier: string, key: number }[];
    selectedUnit?: number,
    // protein: number;
    // carbs: number;
    // fat: number;
    // calories: number;
    // fiber: number| undefined;
    proteinPer100g: number;
    carbsPer100g: number;
    fatPer100g: number;
    caloriesPer100g: number;
    // fiberPer100g: number| undefined;
    // unitEquivalentWeight: number;
    locked?: boolean;
    'original text'?:string;
    'tagged named'?:string;
    'tagged unit'?:string;
};

export var emptyIngredient: Ingredient = {
    key: -1,
    // fdc_id:-1,
    name: '',
    foundName: '',
    quantity: 0,
    units: [],
    selectedUnit: 0,
    proteinPer100g: 0,
    carbsPer100g: 0,
    fatPer100g: 0,
    caloriesPer100g: 0,
    locked: false
};

export type mealList = {
    [mealID: string]: meal
};

export type dailyMeals = {
    [date: string]: mealList
}

export type meal = {
    ingredients: Array<Ingredient>,
    recipeId: string,
    mealName: string,
    mealRank: number,
    recipeName: string,
    source: string | undefined,
    sourceURL: string | undefined,
    imageURL?: string,

};

export var emptyMeal: meal = {
    ingredients: [],
    recipeId: '',
    mealName: '',
    mealRank: 0,
    recipeName: '',
    source: '',
    sourceURL: '',
    // servings:0,
    // proteinPerServing:0,
    // carbsPerServing:0,
    // fatPerServing:0,
    // caloriesPerServing:0
};

export type ErrorMessage = {
    id:string,
    errorMessage:string,
}

export type Recipe = {
    ingredients: Array<Ingredient>,
    recipeId: string,
    recipeName: string,
    source: string | undefined,
    sourceURL: string | undefined,
    like: boolean | null,
    servings:number,
    proteinPerServing:number,
    carbsPerServing:number,
    fatPerServing:number,
    caloriesPerServing:number,
    imageURL?: string,
    mealType?: string[],
};

export var emptyRecipe: Recipe = {
    ingredients: [],
    recipeId: '',
    recipeName: '',
    source: '',
    sourceURL: '',
    like: null,
    servings:1,
    proteinPerServing:0,
    carbsPerServing:0,
    fatPerServing:0,
    caloriesPerServing:0
};


export type userDoe = {
    user_id: string,
    timestamp: number,
    meals: mealList
};

export interface mealType {
    key: number;
    type: string;
};

export interface OnboardingUserFormData {
    username: string;
    birthdate:string;
    gender: string;
    height: number;
    weight: number;
    activityLevel: string;
    goal: string;
    caloricMaintenance: number;
    caloricTarget: number;
    macrosTarget: {
        protein: number,
        carbs: number,
        fat: number,
        // fiber: number
    };
    meals: mealType[];
    // date: string;
    // isMetric: boolean;
};

export type RecipeLike = {
    recipe_id: string;
    likeStatus: boolean | null;
  };

export type sumMacros = {
    protein: number,
    carbs: number,
    fat: number,
    calories: number,
    // fiber: number
};

export const goal2deficit = {
    'extraLoss': -1000,
    'loss': -500,
    'mildLoss': -250,
    'maintain': 0,
    'mildgain': 250,
    'gain': 500,
    'extremeGain': 1000
};

export const goal2WeightDifference = {
    'extraLoss': -0.95,
    'loss': -0.45,
    'mildLoss': -0.23,
    'maintain': 0,
    'mildgain': 0.23,
    'gain': 0.45,
    'extremeGain': 0.95
}

export type weightData = {
    date: string,
    weight: number | null
};
