import { IonItemSliding, IonItem, IonLabel, IonNote, IonItemOptions, IonItemOption, IonIcon, IonButton } from "@ionic/react";
import { create, lockClosed, lockOpen, trash } from "ionicons/icons";
import { Ingredient } from "../../../../utils/types";
import { useHistory, useLocation } from "react-router";
import { useRef, useEffect, useState } from "react";

type IngredientListProps = {
    ingredients: Ingredient[];
    onClickLockIngredient: (key: number) => void;
    date?: string;
    mealID?: string;
    recipeID?: string;
    onClickDeleteIngredient: (key: string) => void;
    onClickAddIngredient: () => void;
    editable: boolean;
    importing: boolean;

}

function IngredientList(props: IngredientListProps) {
    const history = useHistory();
    const location = useLocation();

    useEffect(()=>{
        console.log('ingredient list props', props.ingredients);
    })

    return (
        <>
            {props.ingredients.map((ingredient: Ingredient) => {
                const selectedUnit = ingredient.units ? ingredient.units.find(unit => unit.key === ingredient.selectedUnit) : null;

                return (
                    <>
                        {props.importing && ingredient['original text'] &&
                        <p style={{ fontStyle: 'italic', marginBottom: 0, marginTop: 20, marginLeft: 15 }}>
                            {ingredient['original text']}
                        </p> }
                        <IonItemSliding key={ingredient.key}>
                            <IonItem>
                                <IonLabel>
                                    {ingredient.name}<br />
                                    <IonNote color="medium" className="ion-text-wrap">
                                        {ingredient.quantity}  {selectedUnit?.modifier}
                                    </IonNote>
                                </IonLabel>
                            </IonItem>
                            {props.editable &&
                                <IonItemOptions slot="end">
                                    <IonItemOption color="light" onClick={() => {
                                        props.onClickLockIngredient(Number(ingredient.key))
                                    }}>
                                        <IonIcon
                                            slot="icon-only"
                                            icon={ingredient.locked ? lockClosed : lockOpen}
                                        >
                                        </IonIcon>
                                    </IonItemOption>

                                    <IonItemOption color="primary" onClick={() => {
                                        history.push({
                                            pathname: `/mealplan/editIngredient/`,
                                            state: { date: props.date, mealID: props.mealID, ingredientKey: ingredient.key }
                                        });
                                    }}>
                                        <IonIcon
                                            slot="icon-only"
                                            icon={create} // replace with the icon you want to use for editing

                                        ></IonIcon>
                                    </IonItemOption>

                                    <IonItemOption color="danger" expandable={true} onClick={() => {
                                        props.onClickDeleteIngredient(ingredient.key.toString());

                                    }}>
                                        <IonIcon
                                            slot="icon-only"
                                            icon={trash}
                                        >
                                        </IonIcon>
                                    </IonItemOption>
                                </IonItemOptions>
                            }
                        </IonItemSliding>
                    </>
                )
            })}

            <IonButton
                fill="clear"
                onClick={props.onClickAddIngredient}
                disabled={!props.editable}
            >
                Add ingredient
            </IonButton>
        </>
    );
}

export { IngredientList };