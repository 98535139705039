// authSlice.tsx
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';
import { signOut as firebaseSignOut } from '../utils/firebase';
import { mealType, OnboardingUserFormData } from '../utils/types';
import { RootState } from './store';
import { saveUserGoal } from '../utils/db_connectors/database-connectors';
import { addError } from './errorSlice';

export const signOut = createAsyncThunk('auth/signOut', async (_, thunkAPI) => {
    try {
        await firebaseSignOut(); // call the signOut function from firebaseutils
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export interface AuthState {
    userUid: string | null; // Replace User with the actual type of your user
    isPremium: boolean;
    isAdmin: boolean;
    providerId: string;
    email: string | null; // Add email field
    displayName: string;
    userGoal: OnboardingUserFormData | null;
}

const initialState: AuthState = {
    userUid: null,
    isPremium: false,
    isAdmin: false,
    providerId: '',
    email: '', // Add email field
    displayName: '',
    userGoal: null
};

// Thunk function to save user goal
export const saveUserGoalThunk = createAsyncThunk(
    'auth/saveUserGoal',
    async (userGoal: OnboardingUserFormData, { getState, rejectWithValue, dispatch }) => {
        const state = getState() as RootState;
        const userId = state.auth.userUid;
        if (userId) {
            try {
                await saveUserGoal(userId, userGoal);
                dispatch(addError({ id: new Date().getTime().toString(), errorMessage: 'Successfully saved user goal' }));
                return userGoal; // Return the userGoal data
            } catch (error) {
                const errorMessage = typeof error === 'string' ? error : 'An unknown error occurred';
                dispatch(addError({ id: new Date().getTime().toString(), errorMessage }));
                // throw new Error(errorMessage); // Throw an error to be handled by the calling function
            }
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<string>) => { // Replace User with the actual type of your user
            state.userUid = action.payload;
        },
        setIsPremium: (state, action: PayloadAction<boolean>) => {
            state.isPremium = action.payload;
        },
        setIsAdmin: (state, action: PayloadAction<boolean>) => {
            state.isAdmin = action.payload;
        },
        setProviderId: (state, action: PayloadAction<string>) => { // Add setProviderId reducer
            state.providerId = action.payload;
        },
        setEmail: (state, action: PayloadAction<string | null>) => { // Add setEmail reducer
            state.email = action.payload;
        },
        setDisplayName: (state, action: PayloadAction<string>) => { // Add setDisplayName reducer
            state.displayName = action.payload;
        },
        setUserGoal: (state, action: PayloadAction<OnboardingUserFormData>) => {
            state.userGoal = action.payload;
        },
        setDoeStructure: (state, action: PayloadAction<mealType[]>) => {
            if (state.userGoal) {
                state.userGoal.meals = action.payload;
            }
        },
        logOut: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signOut.fulfilled, (state) => {
            // Reset the state to initial state when signOut is successful
            return initialState;
        });
        builder.addCase(saveUserGoalThunk.fulfilled, (state, action) => {
            // Handle success state if needed, userGoal can be null if error serverside
            if (action.payload) {
                state.userGoal = action.payload;
            }
        });
        builder.addCase(saveUserGoalThunk.rejected, (state, action) => {
            // Handle error state if needed
            console.error('Failed to save user goal:', action.error);
        });
    },
});

export const { setUser, setIsPremium, setIsAdmin, setDisplayName, setEmail, setProviderId, setUserGoal, setDoeStructure } = authSlice.actions;
export const { logOut } = authSlice.actions;

export default authSlice.reducer;