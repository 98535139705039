import { IonButton, IonCheckbox, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonItem, IonList, IonModal, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useState } from "react";
import { getLastMonday, getNextSunday } from "../MealPlan/MealPlanUtils";
import { fetchMealsSuccess } from "../../redux/mealsSlice";
import { loadUserDayOfEating } from "../../utils/db_connectors/database-connectors";
import { getMacrosFromMeals } from "../../utils/misc-utils";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { dailyMeals, Ingredient, mealList } from "../../utils/types";
import { set } from "lodash";
import { LoadingGenie } from "../../components/LoadingGenie/LoadingGenie";

type groceryListPageProps = {

}

type AggregatedIngredients = {
    [ingredientName: string]: {
        [unit: string]: {
            quantity: number;
            checkedStatus: boolean;
        };
    };
};

function GroceryListPage(props: groceryListPageProps) {
    const [dateFrom, setDateFrom] = useState<string>(getLastMonday());
    const [dateTo, setDateTo] = useState<string>(getNextSunday());

    const loadedMeals = useSelector((state: RootState) => state.meals);

    const user = useSelector((state: RootState) => state.auth.userUid);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checkedItems, setCheckedItems] = useState<JSX.Element[]>([]);
    const [uncheckedItems, setUncheckedItems] = useState<JSX.Element[]>([]);

    const [aggregatedIngredients, setAggregatedIngredients] = useState<AggregatedIngredients>(() => {
        const saved = localStorage.getItem('aggregatedIngredients');
        return saved ? JSON.parse(saved) : {};
    });

    useEffect(() => {
        localStorage.setItem('aggregatedIngredients', JSON.stringify(aggregatedIngredients));
    }, [aggregatedIngredients]);


    const fetchData = async() => {
        let currentDate = new Date(dateFrom);
        const dateToDate = new Date(dateTo);
        const ingredientMap: AggregatedIngredients = {};
        var datesToLoad = [];
        var toReturn : {[date: string]: mealList;} = {};

        while (currentDate <= dateToDate) {
            const currentDateString = currentDate.toISOString().split('T')[0];
            if(currentDateString in loadedMeals){
                toReturn[currentDateString] = loadedMeals[currentDateString];
            }
            else if (!(currentDateString in loadedMeals)){
                datesToLoad.push(currentDateString);
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        if(datesToLoad.length > 0){
            const res = await loadUserDoe(datesToLoad);
            Object.entries(res).forEach(([date, temp]) => {
                if (typeof temp === 'object' && temp !== null && 'recipes' in temp) {
                    const recipes = (temp as { recipes: mealList }).recipes;
                    dispatch(fetchMealsSuccess({ date: date, meals: recipes }));
                    toReturn[date] = recipes;
                }
                else {
                    dispatch(fetchMealsSuccess({ date: date, meals: {} }));
                    toReturn[date] = {};
                }
                // toReturn[date] = temp;
            });
        }
        return toReturn;
    };


    async function loadUserDoe(datesToLoad: string[]) {
        const res = await loadUserDayOfEating(user!, datesToLoad);           
        return res;
    }

    async function generateGroceryList() {
        if (user && dateFrom && dateTo) {
            setIsLoading(true);
            var data = await fetchData();
            let currentDate = new Date(dateFrom);
            const dateToDate = new Date(dateTo);
            const ingredientMap: { [ingredientName: string]: { [unit: string]: { quantity: number, checkedStatus: boolean } } } = {};

            while (currentDate <= dateToDate) {
                const currentDateString = currentDate.toISOString().split('T')[0];
                if (currentDateString in data) {
                    const mealList = data[currentDateString];
                    Object.entries(mealList).forEach(([mealID, meal]) => {
                        meal.ingredients.forEach(ingredient => {
                            const selectedUnit = ingredient.units.find(unit => unit.key === ingredient.selectedUnit);
                            if (!ingredientMap[ingredient.name]) {
                                ingredientMap[ingredient.name] = {};
                            }
                            if (selectedUnit) {
                                if (ingredientMap[ingredient.name][selectedUnit.modifier]) {
                                    ingredientMap[ingredient.name][selectedUnit.modifier].quantity += ingredient.quantity??0;
                                } else {
                                    ingredientMap[ingredient.name][selectedUnit.modifier] = { quantity: ingredient.quantity??0, checkedStatus: false };
                                }
                            }
                        });
                    });
                }
                currentDate.setDate(currentDate.getDate() + 1);
            }

            setAggregatedIngredients(prev=>ingredientMap);       
            setIsLoading(false);
        }
    }

    const handleCheckboxChange = (ingredientName: string, unit: string) => {
        setAggregatedIngredients(prevState => ({
            ...prevState,
            [ingredientName]: {
                ...prevState[ingredientName],
                [unit]: {
                    ...prevState[ingredientName][unit],
                    checkedStatus: !prevState[ingredientName][unit].checkedStatus
                }
            }
        }));
    };


    useEffect(() => {
        const sortedEntries = Object.entries(aggregatedIngredients).sort(([a], [b]) => a.localeCompare(b));

        const uncheckedItems = sortedEntries.flatMap(([ingredientName, units]) =>
            Object.entries(units)
                .filter(([unit, { checkedStatus }]) => !checkedStatus)
                .map(([unit, { quantity, checkedStatus }]) => (
                    <IonItem key={`${ingredientName}-${unit}`}>
                        {ingredientName} ({quantity} {unit})
                        <IonCheckbox
                            justify="space-between"
                            checked={checkedStatus}
                            onIonChange={() => handleCheckboxChange(ingredientName, unit)}
                            slot="end"
                        />
                    </IonItem>
                ))
        );
        setUncheckedItems(prev => uncheckedItems);

        const checkedItems = sortedEntries.flatMap(([ingredientName, units]) =>
            Object.entries(units)
                .filter(([unit, { checkedStatus }]) => checkedStatus)
                .map(([unit, { quantity, checkedStatus }]) => (
                    <IonItem key={`${ingredientName}-${unit}`} style={{ color: 'grey' }}>
                        <span style={{ textDecoration: 'line-through', color: 'grey' }}>
                            {ingredientName} ({quantity} {unit})
                        </span>
                        <IonCheckbox
                            justify="space-between"
                            checked={checkedStatus}
                            onIonChange={() => handleCheckboxChange(ingredientName, unit)}
                            slot="end"
                            style={{
                                '--border-color-checked': 'darkgrey',
                                '--checkbox-background-checked': 'darkgrey'
                            }}
                        />
                    </IonItem>
                ))
        );
        setCheckedItems(prev => checkedItems);
    }, [aggregatedIngredients]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Grocery List
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonRow class="ion-justify-content-center" style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>

                    <p style={{ marginTop: 'auto', marginBottom: "auto" }}>From</p>
                    <IonDatetimeButton datetime="dateFrom"></IonDatetimeButton>
                    <IonModal keepContentsMounted={true}>
                        <IonDatetime
                            id="dateFrom"
                            presentation="date"
                            value={dateFrom}
                            onIonChange={(e) => setDateFrom(e.detail.value! as string)}
                        ></IonDatetime>
                    </IonModal>
                    <p style={{ marginTop: 'auto', marginBottom: "auto" }}> to </p>
                    <IonDatetimeButton datetime="dateTo"></IonDatetimeButton>
                    <IonModal keepContentsMounted={true}>
                        <IonDatetime
                            id="dateTo"
                            presentation="date"
                            value={dateTo}
                            min={dateFrom}
                            onIonChange={(e) => { setDateTo(e.detail.value! as string) }}
                        ></IonDatetime>
                    </IonModal>
                </IonRow>
                <IonRow style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <IonButton onClick={() => generateGroceryList()}>Generate grocery list</IonButton>
                </IonRow>
                {isLoading ? <LoadingGenie /> :
                    <>
                <IonList>
                {uncheckedItems}
                </IonList>
                {/* //checked items */}
                <IonList>
                {checkedItems}
                </IonList>
                    </>}
            </IonContent>
        </IonPage >
    )
}

export { GroceryListPage }