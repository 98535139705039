import { IonPage, IonContent, IonBackButton, IonButtons, IonTitle, IonToolbar, IonItem, IonInput, IonButton, IonIcon } from "@ionic/react";
import './macrosPage.css';
import { useContext, useEffect, useState } from "react";
import { loadUserGoal, saveUserGoal } from "../../../../utils/db_connectors/database-connectors";
import { UserContext } from "../../../../App";
import { OnboardingUserFormData } from "../../../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { LoadingGenie } from "../../../../components/LoadingGenie/LoadingGenie";
import { warningOutline } from "ionicons/icons";

function MacrosPageComponent() {
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);

    // const [macrosTarget, setMacrosTarget] = useState({ protein: 0, carbs: 0, fat: 0 });
    // const [caloricTarget, setCaloricTarget] = useState(0);
    const [userGoal, setUserGoal] = useState<OnboardingUserFormData>();
    const [loading, isLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        loadUserGoal(user!, dispatch).then((temp) => {
            setUserGoal(temp);
            isLoading(false);
        });
    }, []);


    function onChangeMacro(macroName: string, newValue: number) {
        setUserGoal(prev => {
            if (prev && prev.macrosTarget) {
                const newMacrosTarget = {
                    ...prev.macrosTarget,
                    [macroName]: newValue
                };
                const newCaloricTarget = newMacrosTarget.protein * 4 + newMacrosTarget.carbs * 4 + newMacrosTarget.fat * 9;
                return {
                    ...prev,
                    macrosTarget: newMacrosTarget,
                    caloricTarget: newCaloricTarget,
                    // date: new Date().toISOString(),
                }
            }
        });
    }

    return (
        <IonPage>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="#" />
                </IonButtons>
                <IonTitle>Macros</IonTitle>
            </IonToolbar>
            {loading ? <IonContent><LoadingGenie /></IonContent> :
                <IonContent>
                    <IonItem>
                        <IonInput
                            className="input-align-right"
                            label="Protein (g)"
                            type="number"
                            placeholder="0"
                            value={userGoal?.macrosTarget?.protein}
                            onIonInput={e => onChangeMacro('protein', Number(e.detail.value))}
                        >
                        </IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput
                            className="input-align-right"
                            label="Carbohydrates (g)"
                            type="number"
                            placeholder="0"
                            value={userGoal?.macrosTarget?.carbs}
                            onIonInput={e => onChangeMacro('carbs', Number(e.detail.value))}
                        >
                        </IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput
                            className="input-align-right"
                            label="Fats (g)"
                            type="number"
                            placeholder="0"
                            value={userGoal?.macrosTarget?.fat}
                            onIonInput={e => onChangeMacro('fat', Number(e.detail.value))}
                        >
                        </IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput
                            className="input-align-right"
                            label="Calories (kcal)"
                            type="number"
                            placeholder="0"
                            value={userGoal?.caloricTarget}
                            readonly
                        >
                        </IonInput>
                    </IonItem>
                    <IonButton expand='block'>Save</IonButton>
                </IonContent>
            }
        </IonPage>
    );
}

export { MacrosPageComponent };