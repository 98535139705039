import { Collapse, Space, InputNumber, Row, Switch, Button } from 'antd';
import { ExperimentOutlined } from '@ant-design/icons';
import { IonCard, IonCol, IonRow } from '@ionic/react';

type NutritionDetailsCardProps = {
    // totalCalories: number,
    // totalCarbs: number,
    // totalProteins: number,
    // totalFats: number,
    // disableMacros: boolean,
    // proteinObjective: number,
    // setProteinObjective: (obj: number) => void,
    // carbsObjective: number,
    // setCarbsObjective: (obj: number) => void,
    // fatObjective: number,
    // setFatObjective: (obj: number) => void,
    // calorieObjective: number,
    // onChangeSwitch: () => void,
    sumMacros: {
        protein: number | undefined,
        carbs: number | undefined,
        fat: number | undefined,
        calories: number | undefined,
    }   
    isAverageCard : boolean // sumProtein: number | undefined,
    // // sumCarbs: number | undefined,
    // // sumFat: number | undefined,
    // // sumCalories: number | undefined,
    // onChangeProteinObjective: (value: number | null) => void,
    // onChangeCarbObjective: (value: number | null) => void,
    // onChangeFatObjective: (value: number | null) => void,
    // // onClickReset: () => void,
    // onClickAdjustRecipe: () => void,
    // restricted:boolean,
}


function NutritionDetailsCard(props: NutritionDetailsCardProps){
    const { Panel } = Collapse;
    return (
        <>
        <IonCard>
            <IonRow>
                <IonCol>
                    <h1>{props.sumMacros.calories} kcal</h1>
                </IonCol>
                <IonCol>
                    <strong>{props.sumMacros.protein}</strong> g protein<br/>
                    <strong>{props.sumMacros.carbs}</strong> g carbs<br/>
                    <strong>{props.sumMacros.fat}</strong> g fats
                </IonCol>
            </IonRow>
            {props.isAverageCard && (
                <IonRow style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <p style={{ marginRight: '10px' }}><em>daily average values</em></p>
                </IonRow>
            )}
        </IonCard>
        
        
        </>
    )
};

export default NutritionDetailsCard;