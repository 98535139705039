import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonList, IonItem, IonThumbnail, IonLabel, IonIcon, IonButton, IonActionSheet } from "@ionic/react"
import { ErrorMessage, mealList } from "../../../../utils/types"
import { ellipsisVertical, refreshOutline } from "ionicons/icons"
import { useState } from "react"
import { addError } from "../../../../redux/errorSlice"
import { fetchMealsSuccess } from "../../../../redux/mealsSlice"
import { generateMealPlan } from "../../Services/mealplanServices"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { LoadingGenie } from "../../../../components/LoadingGenie/LoadingGenie"

type RecipeCardLightProps = {
    date: string,
    meals: mealList
}

function RecipeCardLight(props: RecipeCardLightProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state.auth.userUid);
    const dispatch = useDispatch();
    const [showActionSheet, setShowActionSheet] = useState(false);

    const onClickGenerateMealPlan = async () => {
        setIsLoading(prev => true);
        try {
            const res = await generateMealPlan(user!, [props.date!], dispatch);
            if ('errors' in res && res.errors.length > 0) {
                for (const error of res.errors as ErrorMessage[]) {
                    dispatch(addError({ id: error.id, errorMessage: error.errorMessage }));
                }
                // return;
            }
            if (res.data.length > 0) {
                // Await the dispatch if it returns a Promise. This requires your fetchMealsSuccess action creator to be thunk-based or similar.
                await dispatch(fetchMealsSuccess({ date: props.date, meals: res.data[0] }));
                dispatch(addError({ id: new Date().getTime().toString(), errorMessage: "Successfully generated meal plan" }));
                // Now that the dispatch is complete, set isLoading to false.
            }
            setIsLoading(prev => false);

        } catch (error) {
            console.error(error);
            // Handle error here
            setIsLoading(prev => false); // Ensure isLoading is set to false even if there's an error.
        }
    }

    const formattedDate = new Date(props.date).toLocaleDateString('en-US', {
        weekday: 'long',
        // year: 'numeric',
        month: 'long',
        day: 'numeric',
    });


    return (
        <IonCard>
            <IonCardHeader>
                <IonItem lines="none">
                    <div style={{ display: 'flex', flexGrow: 1 }}>
                        <IonCardTitle>{formattedDate}</IonCardTitle>
                    </div>
                    <IonIcon slot="end" icon={ellipsisVertical} id={"open-action-sheet" + props.date} onClick={()=>setShowActionSheet(true)} />
                </IonItem>
                <IonActionSheet
                    trigger={"open-action-sheet" + props.date}
                    header="Actions"
                    buttons={[
                        {
                            text: 'Replace day',
                            handler: async () => {
                                onClickGenerateMealPlan();

                            },
                        },
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            data: {
                                action: 'cancel',
                            },
                        },
                    ]}
                >

                </IonActionSheet>

            </IonCardHeader>
            <IonCardContent>
                {isLoading ? <LoadingGenie />
                    :
                    <IonList>
                        {Object.entries(props.meals || {}).map(([mealID, meal]) => (
                            <IonItem key={mealID}>
                                <IonThumbnail slot="start">
                                    <img
                                        alt="Silhouette of mountains"
                                        src={meal.imageURL ? meal.imageURL : "https://ionicframework.com/docs/img/demos/thumbnail.svg"}
                                        style={{ borderRadius: '50%', width: '48px', height: '48px', objectFit: 'cover' }}
                                    />
                                </IonThumbnail>
                                <IonLabel>{meal.recipeName}</IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                }
            </IonCardContent>


        </IonCard>
    )
}

export { RecipeCardLight }