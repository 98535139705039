import { fetchMealsSuccess } from "../../redux/mealsSlice";
import { loadUserDayOfEating } from "../../utils/db_connectors/database-connectors";
import { Ingredient, mealList, unitType } from "../../utils/types";

export function getLastMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const lastMonday = new Date(today);
    
    // Calculate the difference to the last Monday
    const diff = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    lastMonday.setDate(today.getDate() - diff);
    const formattedDate = lastMonday.toISOString().split('T')[0];

    return formattedDate;
}

export function getNextSunday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const nextSunday = new Date(today);
    
    // Calculate the difference to the next Sunday
    const diff = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
    nextSunday.setDate(today.getDate() + diff);
    const formattedDate = nextSunday.toISOString().split('T')[0];

    return formattedDate;
}

export async function loadMeals(dateFrom: Date, dateTo: Date, loadedMeals: { [date: string]: mealList }, dispatch: any, user: string) {

    //iterte from dateFrom to dateTo 
    const currentDate = new Date(dateFrom);
    const daysToLoad = [];
    while (currentDate <= dateTo) {
        const formattedDate = currentDate.toISOString().split('T')[0];
        if (!(formattedDate in loadedMeals)) {
            daysToLoad.push(formattedDate);
            //load the meals for the date
            // loadedMeals[formattedDate] = await loadMealsForDate(formattedDate);
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    if (daysToLoad.length == 0) {
        return;
    }

    const res = await loadUserDayOfEating(user!, daysToLoad);

    //add newly loaded days to db, todo do this from redux store action
    Object.entries(res).forEach(([date, temp]) => {
        if (typeof temp === 'object' && temp !== null && 'recipes' in temp) {
            const recipes = (temp as { recipes: any }).recipes;
            dispatch(fetchMealsSuccess({ date: date, meals: recipes }));
        }
        else {
            dispatch(fetchMealsSuccess({ date: date, meals: {} }));
        }
    });

}

export function getDailyMacroAverage(meals: { [date: string]: mealList }) {

    const macros = {
        protein: 0,
        carbs: 0,
        fats: 0,
        calories: 0,
    };

    type MacroKey = keyof typeof macros;

    Object.values(meals).forEach(mealList => {
        Object.values(mealList).forEach(meal => {
            meal.ingredients.forEach(ingredient => {
                const selectedUnit : unitType|undefined = ingredient.units.find(unit => unit.key === ingredient.selectedUnit);
                (Object.keys(macros) as MacroKey[]).forEach(key => {
                    const per100gKey = `${key}Per100g` as keyof Ingredient;
                    const value = ingredient[per100gKey];
                    if (typeof value === 'number' && selectedUnit) {
                        macros[key] += value * (ingredient.quantity ? ingredient.quantity : 0) * selectedUnit.gram_weight / 100;
                    }
                });
            });
        });
    });

    // Get number of non-empty days
    const nonEmptyDays = Object.keys(meals).filter(date => Object.keys(meals[date]).length > 0).length;

    if (nonEmptyDays > 0) {
        (Object.keys(macros) as MacroKey[]).forEach(key => {
            macros[key] = Math.round(macros[key] / nonEmptyDays);
        });
    }

    return macros;
}