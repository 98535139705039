import { useEffect, useState } from "react";
import { Recipe } from "../../../../utils/types";
import { IonButton } from "@ionic/react";

type recipeCardProps = {
    recipe: Recipe;
};

function RecipeCard(props: recipeCardProps) {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleCardClick = () => {
        setIsExpanded(!isExpanded);
    };
    
    return (
        <>
            <div style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                borderRadius: '15px',
                overflow: 'hidden',
                zIndex: '1', // Ensure it is above the background
                transition: 'max-height 0.3s ease-in-out' // Smooth transition for maxHeight

            }}>
                <img src={props.recipe.imageURL} alt={props.recipe.recipeName} style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }} />

                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                    color: 'white',
                    textAlign: 'center',
                    padding: '10px',
                    transition: 'max-height 0.5s ease-in-out', // Smooth transition for maxHeight
                    maxHeight: isExpanded ? '100%' : '50px', // Adjust maxHeight for smooth transition
                    overflow: 'hidden'
                }}>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <img src="/path/to/logo.png" alt="Logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} /> */}
                        <h2 style={{ margin: '0' }}>{props.recipe.recipeName}</h2>
                            <span style={{ marginLeft: 'auto', marginRight: 0 }}
                                onClick={handleCardClick}
                            >
                                ⬇️
                            </span>
                    </div>

                    <div style={{
                        marginTop: '10px',
                            // maxHeight: isExpanded ? '100%' : '50px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        transition: 'max-height 0.3s ease-in-out' // Smooth transition for maxHeight
                    }}>
                        Macros
                        <p>Calories: {props.recipe.caloriesPerServing}</p>
                        <p>Protein: {props.recipe.proteinPerServing}</p>
                        <p>Fat: {props.recipe.fatPerServing}</p>
                        <p>Carbs: {props.recipe.carbsPerServing}</p>
                        Ingredients
                            <ul style={{ textAlign: 'left', padding: '10px', margin: '10px 0' }}>
                            {props.recipe.ingredients.map((ingredient, index) => {
            const selectedUnit = ingredient.units ? ingredient.units.find(unit => unit.key === ingredient.selectedUnit) : null;
            return (
                <li key={index}>
                    {ingredient.quantity} {selectedUnit ? selectedUnit.modifier : ''} {ingredient.name}
                </li>
            );
        })}
                            </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export { RecipeCard };