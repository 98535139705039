import { match } from 'react-router';
import backendUrl from '../../../utils/api_connectors/backend';
import { Ingredient, Recipe, mealList } from '../../../utils/types';
import { addError } from '../../../redux/errorSlice';
import { Dispatch } from 'redux';

async function optimize(ingredients:Ingredient[], objective:{[name:string]:number}): Promise<Ingredient[]>{
    //TODO no requestOptions reaching API 
    const requestOptions = {
        method: 'POST',
        headers: {"content-type":"application/json"},
        body: JSON.stringify({ingredients:ingredients, objective:objective})
    };
    const response = await fetch(backendUrl+'optimise5', requestOptions)
    var data = await response.json();
    return data;
}

async function optimize_doe(meals:mealList, objective:{[name:string]:number}, sumMacros:{}){
    const requestOptions = {
        method: 'POST',
        headers: {"content-type":"application/json"},
        body: JSON.stringify({meals:meals, objective:objective, currentMacros:sumMacros})
    }
    try{
        const response = await fetch(backendUrl+'optimiseIIFYM', requestOptions)
        var data = await response.json();
        return data;
    }catch(e){
        return {'error': 'Server connection error'}
    }
}

async function generateMealPlan(user_id:string, dates:string[], dispatch:Dispatch){
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const requestOptions = {
        method: 'POST',
        headers: {"content-type":"application/json"},
        body: JSON.stringify({user_id:user_id, dates:dates, timezone: timezone})
    }
    try{
        const response = await fetch(backendUrl+'generateMealPlan', requestOptions)
        var data = await response.json();
        return data;
    }catch(e){
        dispatch(addError({id: new Date().getTime().toString() ,errorMessage: 'Server connection error'}))
        return {'error': 'Server connection error'}
    }
}

async function saveUsersRecipe(user_id:string, recipe:Recipe, dispatch:Dispatch){
    const requestOptions = {
        method: 'POST',
        headers: {"content-type":"application/json"},
        body: JSON.stringify({user_id:user_id, recipe:recipe})
    }
    try{
        const response = await fetch(backendUrl+'saveUsersRecipe', requestOptions)
        var data = await response.json();
        return data;
    }catch(e){
        dispatch(addError({id: new Date().getTime().toString() ,errorMessage: 'Server connection error'}))
        return {'error': 'Server connection error'}
    }
}

async function saveCleanedRecipe(recipe:Recipe, collection:string, status:string){
    // TODO 
    const requestOptions = {
        method: 'POST',
        headers: {"content-type":"application/json"},
        body: JSON.stringify({recipe:recipe, status:status, collection:collection})
    }
    try{
        const response = await fetch(backendUrl+'markRecipeCleaned', requestOptions)
        var data = await response.json();
        return data;
    }catch(e){
        return {'error': 'Server connection error'}
    }
}

async function saveRecipeToCommon(recipe:Recipe){
    // TODO 
    const requestOptions = {
        method: 'POST',
        headers: {"content-type":"application/json"},
        body: JSON.stringify({recipe:recipe})
    }
    try{
        const response = await fetch(backendUrl+'saveRecipeToCommon', requestOptions)
        var data = await response.json();
        return data;
    }catch(e){
        return {'error': 'Server connection error'}
    }
}



export {optimize, optimize_doe, generateMealPlan, saveUsersRecipe, saveCleanedRecipe, saveRecipeToCommon};