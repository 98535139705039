import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { getWeightData, writeUserWeight } from '../features/DashboardPage/Services/dashboardServices';
import { RootState } from './store';

interface WeightEntry {
  date: string;
  weight: number;
}

interface DashboardState {
  weightEntries: Record<string, number>;
  interpolatedWeightEntries: Record<string, number>;
  weightDataLoadingStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  rollingAverageWeightData: Record<string, number | null>;

}

const initialState: DashboardState = {
  weightEntries: {},
  interpolatedWeightEntries: {},
  weightDataLoadingStatus: 'idle',
  rollingAverageWeightData: {},
};

// Thunk function to retrieve weight entries from the database with a dateFrom parameter
export const getWeightEntries = createAsyncThunk(
  'dashboard/getWeightEntries',
  async (params: { dateFrom: string }, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    //find intervals already in store
    var dateFrom = new Date(params.dateFrom);
    var currentDate = dateFrom;
    var dateTo = new Date();
    var intervalList = [];
    var tempIntervalTo: Date | undefined = undefined;
    var tempIntervalFrom: Date | undefined = undefined;
    const user = state.auth.userUid;

    while (currentDate <= dateTo) {
      const currentDateString = currentDate.toISOString().split('T')[0];
      // If current date isn't in store
      if (!(currentDateString in state.dashboard.weightEntries)) {
        // If interval is empty, set the beginning of a new interval
        if (tempIntervalFrom === undefined) {
          tempIntervalFrom = new Date(currentDate);
        } else {
          // Else grow the current interval
          tempIntervalTo = new Date(currentDate);
        }

        if (currentDateString === dateTo.toISOString().split('T')[0]) {
          intervalList.push([tempIntervalFrom, tempIntervalTo]);
        }
      } else {
        // If currentDate is in store but prev wasn't, that's a new interval
        if (tempIntervalTo === new Date(currentDate.setDate(currentDate.getDate() - 1))) {
          intervalList.push([tempIntervalFrom, tempIntervalTo]);
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Fetch data for the intervals
    try {
      let toReturn: Record<string, number> = {};
      for (const [intervalFrom, intervalTo] of intervalList) {
        const intervalFromString = intervalFrom!.toISOString().split('T')[0];
        const intervalToString = intervalTo?.toISOString().split('T')[0] ?? intervalFromString;
        const response = await getWeightData(user!, intervalFromString, intervalToString);
        response.forEach((entry: WeightEntry) => {
          toReturn[entry.date] = entry.weight;
        });
      }
      return toReturn;
    } catch (error) {
      return rejectWithValue("");
    }
    // // Filter and return the entries within the specified date range
    // const filteredEntries = Object.keys(state.dashboard.weightEntries)
    //   .filter((date) => date >= params.dateFrom && date <= dateTo.toISOString().split('T')[0])
    //   .map((date) => ({
    //     date,
    //     weight: state.dashboard.weightEntries[date],
    //   }));

  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    addWeightEntry: (state, action: PayloadAction<{ date: string; weight: number }>) => {
      state.weightEntries[action.payload.date] = action.payload.weight;
    },
    updateWeightEntries: (state, action: PayloadAction<Record<string, number>>) => {
      state.weightEntries = action.payload;
    },
    updateInterpolatedWeightEntries: (state, action: PayloadAction<Record<string, number>>) => {
      state.interpolatedWeightEntries = action.payload;
    },
    updateRollingAverageWeightData: (state, action: PayloadAction<Record<string, number>>) => {
      state.rollingAverageWeightData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWeightEntries.pending, (state) => {
      state.weightDataLoadingStatus = 'loading';
    })
      .addCase(getWeightEntries.fulfilled, (state, action) => {
        state.weightDataLoadingStatus = 'succeeded';
        state.weightEntries = { ...state.weightEntries, ...action.payload };

      })
      .addCase(getWeightEntries.rejected, (state) => {
        state.weightDataLoadingStatus = 'failed';
      });
  },
});

export const { addWeightEntry,updateWeightEntries, updateInterpolatedWeightEntries, updateRollingAverageWeightData } = dashboardSlice.actions;

export type {WeightEntry, DashboardState};

export default dashboardSlice.reducer;