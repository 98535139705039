import { Middleware } from 'redux';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { addWeightEntry, getWeightEntries, updateInterpolatedWeightEntries, updateRollingAverageWeightData, updateWeightEntries } from './dashboardSlice';
import { getLast7DaysFullString } from '../features/DashboardPage/Services/dashboardServices';

const compute7DayRollingAverage = (data: Record<string, number>) => {
    const dates = Object.keys(data).sort();
    const rollingAverages: Record<string, number> = {};

    for (let i = 0; i < dates.length; i++) {
        const start = Math.max(0, i - 6);
        const end = i + 1;
        const subset = dates.slice(start, end);
        const sum = subset.reduce((acc, date) => acc + Number(data[date]), 0);
        const average = sum / subset.length;
        rollingAverages[dates[i]] = Math.floor(average * 10) / 10; // Round down to 1st decimal place
    }

    return rollingAverages;
};

const interpolateMissingValues = (data: Record<string, number | null>) => {
    const dates = getLast7DaysFullString();
    // const dates = Object.keys(data).sort();
    for (let i = 0; i < dates.length; i++) {
        if (data[dates[i]] === undefined || data[dates[i]] === null) {
            let prevIndex = i - 1;
            let nextIndex = i + 1;

            while (prevIndex >= 0 && data[dates[prevIndex]] === undefined) {
                prevIndex--;
            }

            while (nextIndex < dates.length && data[dates[nextIndex]] === undefined) {
                nextIndex++;
            }

            if (prevIndex >= 0 && nextIndex < dates.length) {
                const prevValue = data[dates[prevIndex]]!;
                const nextValue = data[dates[nextIndex]]!;
                const interpolatedValue = prevValue + (nextValue - prevValue) * ((i - prevIndex) / (nextIndex - prevIndex));
                data[dates[i]] = interpolatedValue;
            }
        }
    }
    return data;
};

const interpolateMiddleware: Middleware<{}, RootState> = store => next => (action) => {
    // console.log('Middleware triggered:', action);

    const result = next(action);
    if (action && typeof action === 'object') {
        const actionObject = action as { type: string };
        if ('type' in actionObject && typeof actionObject.type === 'string' 
            && (actionObject.type === addWeightEntry.type || actionObject.type === getWeightEntries.fulfilled.type)) {
            const state = store.getState();
            const weightData = state.dashboard.weightEntries;
             // Create a copy of weightData with null values for missing dates
             const weightDataWithNulls: Record<string, number | null> = { ...weightData };
             Object.keys(weightData).forEach(date => {
                 if (weightData[date] === undefined) {
                     weightDataWithNulls[date] = null;
                 }
             });
             // Interpolate missing values
             const interpolatedWeightData = interpolateMissingValues(weightDataWithNulls);
             // Dispatch action to update weightEntries with completedWeightData
             store.dispatch(updateInterpolatedWeightEntries(interpolatedWeightData as Record<string, number>));
             // Compute 7-day rolling averages based on completed weight data
             const rollingAverageData = compute7DayRollingAverage(interpolatedWeightData as Record<string, number>);
             // Dispatch action to update interpolatedData with rollingAverageData
             store.dispatch(updateRollingAverageWeightData(rollingAverageData));
        }


        return result;
    }
};

export default interpolateMiddleware;