import { IonCard, IonCardContent, } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect, useState } from "react";
import { goal2deficit, goal2WeightDifference } from "../../../../utils/types";
import './OnTrackCard.css'; // Import the CSS file

type OnTrackCardProps = {
}

function OnTrackCard(props: OnTrackCardProps) {

    const rollingAverageWeightData = useSelector((state: RootState) => state.dashboard.rollingAverageWeightData);
    const userGoal = useSelector((state: RootState) => state.auth.userGoal);
    const [onTrack, setOnTrack] = useState<boolean | null>(null);
    const [actualWeightDifference, setActualWeightDifference] = useState<number | null>(null);
    const [targetWeightDifference, setTargetWeightDifference] = useState<number | null>(null);


    useEffect(() => {
        console.log('rolling average', rollingAverageWeightData);
        console.log('usergoal', userGoal);
        if (rollingAverageWeightData.length === 0 || userGoal == null) {
            setOnTrack(null);
            return;
        }

        const targetWeightDiff = goal2WeightDifference[userGoal.goal as keyof typeof goal2WeightDifference];
        setTargetWeightDifference(targetWeightDiff);

        const todaysDate = (new Date()).toISOString().split('T')[0];
        const date7DaysAgo = new Date();
        date7DaysAgo.setDate(date7DaysAgo.getDate() - 7);
        const date7DaysAgoString = date7DaysAgo.toISOString().split('T')[0];
        const todaysWeight = rollingAverageWeightData[todaysDate];
        const weight7DaysAgo = rollingAverageWeightData[date7DaysAgoString];

        if (todaysWeight !== undefined && weight7DaysAgo !== undefined && todaysWeight !== null && weight7DaysAgo !== null) {

            let actualWeightDiff = todaysWeight - weight7DaysAgo;
            actualWeightDiff = parseFloat(actualWeightDiff.toFixed(2)); // Round to 1st decimal place

            setActualWeightDifference(actualWeightDiff);

            // If target and actual are the same sign, i.e., losing when willing to lose and gaining when willing to gain
            if (targetWeightDiff * actualWeightDiff > 0) {
                setOnTrack(true);
                // const percentageDifference = Math.abs((actualWeightDiff - targetWeightDiff) / targetWeightDiff) * 100;
                
            } else {
                setOnTrack(false);
            }
        } else {
            setOnTrack(null);
        }
    }, [rollingAverageWeightData, userGoal]);

    useEffect(() => {
        console.log('onTrack', onTrack);
    }, [onTrack]);

    const getCardClass = () => {
        if (onTrack === null) {
            return 'card-grey';
        } else if (onTrack === true && actualWeightDifference !== null && targetWeightDifference !== null) {
            const percentageDifference = Math.abs((actualWeightDifference - targetWeightDifference) / targetWeightDifference) * 100;
            return percentageDifference <= 10 ? 'card-green' : 'card-yellow';
        } else {
            return 'card-red';
        }
    };

    return (
        <IonCard className={getCardClass()}>
            <IonCardContent>
                <h1>{onTrack == null ? 'Not enough data' : onTrack ? 'On Track' : 'Off Track'}</h1>
                <p>{actualWeightDifference && actualWeightDifference >0?'+':''}{actualWeightDifference} kg difference for a target {targetWeightDifference} kg weekly</p>
                <p>difference based off 7-day-rolling-average data between today and seven days ago</p>
            </IonCardContent>
        </IonCard>
    );
}
export { OnTrackCard };