import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLastMonday, getNextSunday } from '../features/MealPlan/MealPlanUtils';
import { getTodaysDay } from '../utils/misc-utils';

export interface UXState {
    swipeView: boolean;
    segment: string;
    weekViewDateFrom: string;
    weekViewDateTo: string;
    dayViewDate: string;
}

const initialState: UXState = {
    swipeView: true,
    segment: 'browse',
    weekViewDateFrom: getLastMonday(),
    weekViewDateTo: getNextSunday(),
    dayViewDate: getTodaysDay(),
};

const uxSlice = createSlice({
    name: 'ux',
    initialState,
    reducers: {
        setSwipeView: (state, action: PayloadAction<boolean>) => {
            state.swipeView = action.payload;
        },
        changeSegment: (state, action: PayloadAction<string>) => {
            state.segment = action.payload;
        },
        setWeekViewDateFrom: (state, action: PayloadAction<string>) => {
            state.weekViewDateFrom = action.payload;
        },
        setWeekViewDateTo: (state, action: PayloadAction<string>) => {
            state.weekViewDateTo = action.payload;
        },
        setDayViewDate: (state, action: PayloadAction<string>) => {
            state.dayViewDate = action.payload;
        },
    },
});

export const { setSwipeView, changeSegment, setWeekViewDateFrom, setWeekViewDateTo, setDayViewDate } = uxSlice.actions;

export default uxSlice.reducer;